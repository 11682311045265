import React, { useState } from "react";

import "../resource/Slick.css";

import Slider from "react-slick";

import { Navigation, Pagination, EffectFade, Scrollbar } from "swiper";

import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { Carousel, Image } from "react-bootstrap";

const HeroCarousel = ({ articles }) => {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "transparent",
          zIndex: "999 !important",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "transparent",
          zIndex: "999 !important",
        }}
        onClick={onClick}
      />
    );
  }

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  };

  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    return isTablet ? children : null;
  };

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loadigPage, setLoadingPage] = useState(true);

  const { user } = useSelector((state) => state.auth);
  // const { articles, isLoading, isError, message } = useSelector(
  //    (state) => state.article
  // );
  let articleBanner = [];
  if (window.location.pathname === "/") {
    if (articles) {
      articleBanner = articles.filter((e) => e.isBanner === true);
    }
  }

  let articles2 = [];
  if (articles) {
    articles2 = articles.slice(0, 3);
  }

  const settings = {
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <>
      {articleBanner.length > 0 ? (
        <>
          <Carousel pause="hover" className="bg-dark" interval={10000}>
            {articleBanner.map((article) => (
              <Carousel.Item key={article._id}>
                <Link
                  to={`/${article.enTitle}`}
                  state={{ articleId: article._id }}
                >
                  <Image
                    style={{ filter: "brightness(0.75)" }}
                    src={article.cover}
                    alt={article.title}
                    fluid
                  />
                  <Carousel.Caption
                    className="carousel-caption ft-sarabun"
                    style={{ top: "50% !important" }}
                  >
                    <h1
                      style={{
                        color: "white",
                        cursor: "pointer",
                        transform: "translateY(-50%)",
                        fontSize: "46px",
                      }}
                    >
                      {article.title}
                    </h1>
                    <p>{article.author.name}</p>
                  </Carousel.Caption>
                </Link>
              </Carousel.Item>
            ))}
          </Carousel>
        </>
      ) : (
        <>
          <Carousel pause="hover" className="bg-dark" interval={10000}>
            {articles2.map((article) => (
              <Carousel.Item key={article._id}>
                <Link
                  to={`/${article.enTitle}`}
                  state={{ articleId: article._id }}
                >
                  <Image
                    style={{ filter: "brightness(0.75)" }}
                    src={article.cover}
                    alt={article.title}
                    fluid
                  />
                  <Carousel.Caption
                    className="carousel-caption ft-sarabun"
                    style={{ top: "50% !important" }}
                  >
                    <h1
                      style={{
                        color: "white",
                        cursor: "pointer",
                        transform: "translateY(-50%)",
                        fontSize: "46px",
                      }}
                    >
                      {article.title}
                    </h1>
                    <h3>{article.author.name}</h3>
                  </Carousel.Caption>
                </Link>
              </Carousel.Item>
            ))}
          </Carousel>
        </>
      )}
    </>
  );
};

const TextHeader = styled.p`
  position: "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%, -50%)";
  color: "white";
  fontsize: "46px";
  fontweight: "bold";
  textshadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px";
  lineheight: "3.5rem";
`;

const TextName = styled.p`
  position: "absolute";
  top: "70%";
  left: "25%";
  transform: "translate(-50%, -50%)";
  color: "white";
  fontsize: "26px";
  textshadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px";
`;

export default HeroCarousel;
