import { Button, Form, Image, Input, message } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';
import '../css/Form.css';
import '../css/Login.css';
import userImg from '../../assets/img/user2.png';

import { login, reset } from '../../features/auth/authSlice';

const Login = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const {
      user,
      isLoading,
      isError,
      isSuccess,
      message: errorMessage,
   } = useSelector((state) => state.auth);

   useEffect(() => {
      if (isError) {
         console.error(message);
      }

      if (isSuccess || user) {
         console.log('success');
         navigate('/dashboard');
      }

      dispatch(reset());
   }, [user, isError, isSuccess, message, navigate, dispatch]);

   const onFinish = (values) => {
      dispatch(login(values));
   };

   const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
   };

   const errorMsg = () => {
      message.error(errorMessage);
   };

   return (
      <div className='main'>
         {isError && errorMsg()}
         <div className='sub-main'>
            <div>
               <div className='imgs'>
                  <div className='contaier-image'>
                     <Image src={userImg} preview={false} />
                  </div>
               </div>
               <div className='form-block'>
                  <Form
                     name='basic'
                     labelCol={{
                        span: 8,
                     }}
                     wrapperCol={{
                        span: 16,
                     }}
                     initialValues={{
                        remember: true,
                     }}
                     onFinish={onFinish}
                     onFinishFailed={onFinishFailed}
                     autoComplete='off'>
                     <Form.Item
                        label='Username'
                        name='username'
                        rules={[
                           {
                              required: true,
                              message: 'Please input your username!',
                           },
                        ]}>
                        <Input />
                     </Form.Item>

                     <Form.Item
                        label='Password'
                        name='password'
                        rules={[
                           {
                              required: true,
                              message: 'Please input your password!',
                           },
                        ]}>
                        <Input.Password />
                     </Form.Item>

                     <Form.Item
                        wrapperCol={{
                           offset: 8,
                           span: 16,
                        }}>
                        <Button type='primary' htmlType='submit'>
                           Submit
                        </Button>
                     </Form.Item>
                  </Form>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Login;
