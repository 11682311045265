import { Avatar, Button, Col, Form, Image, Input, List, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Loading from '../../components/Loading';

import { getArticlesByAuthor } from '../../features/article/articleSlice';
import { getAuthorById, updateAuthor } from '../../features/author/authorSlice';
import DefaultLayout from '../components/DefaultLayout ';
import Resizer from 'react-image-file-resizer';
import { uploadPic } from '../../utils/connect';
import { toast } from 'react-toastify';
import { getPhotoGrapherDetail, updatePhotoGrapher } from '../../features/photoGrapher/photoGrapherSlice';
import { getArticlesByPhotoGrapher } from '../../features/article/articleSlice';

const PhotoGrapherDetail
 = () => {
   const dispatch = useDispatch();
   const location = useLocation();
   const [form] = Form.useForm();
   const [media, setMedia] = useState(null);
   const [mediaPreview, setMediaPreview] = useState(null);
   const [loadigPage, setLoadingPage] = useState(true);
   const { state } = location;
   const {
      articles,
      isLoading: isArticleLoading,
      isError: isArticleError,
      message,
   } = useSelector((state) => state.article);
   const { photoGrapher, isSuccess, isLoading, isError } = useSelector(
      (state) => state.photoGrapher
   );
 



   useEffect(() => {
      if (isError) {

      }

   //   dispatch(getArticlesByAuthor(state.record));
      dispatch(getPhotoGrapherDetail(state.record));
      dispatch(getArticlesByPhotoGrapher(state.record))
     
      form.setFieldsValue({
         name: photoGrapher.name,
         bio: photoGrapher.bio,
         email: photoGrapher.email,
         tel: photoGrapher.tel,
      });
   }, [state.record, photoGrapher.name, form]);

   let articles2 = [];

   const onFinish = async (values) => {
      let profilePicUrl;
      if (media !== null) {
         profilePicUrl = await uploadPic(media);
      }
      if (media !== null && !profilePicUrl) {
         setLoadingPage(false);
         toast.error('Error Uploading Image');
      }

      if (isSuccess) {
         toast.success('แก้ไขข้อมูลสำเร็จ', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
         });
      }

      values.profilePicUrl = profilePicUrl;
      values.userId = state.record;

      dispatch(updatePhotoGrapher(values));
      window.location.reload()
   };

   const handelChange = (e) => {
      setMediaPreview(URL.createObjectURL(e.target.files[0]));

      let fileInput = false;
      if (e.target.files[0]) {
         fileInput = true;
      }
      if (fileInput) {
         try {
            Resizer.imageFileResizer(
               e.target.files[0],
               500,
               500,
               'JPEG',
               100,
               0,
               (uri) => {
    
                  setMedia(uri);
               },
               'base64',
               500,
               500
            );
         } catch (err) {
     
         }
      }
   };

//    if (isLoading || isArticleLoading) {
//       return <Loading />;
//    }

   return (
      <DefaultLayout>
       {isLoading ? (
        <>
        <Loading />
        </>
       ) : (
        <>
      {photoGrapher && (
          <Row gutter={24}>
          <Col md={12} lg={12} xs={24} sm={24}>
           {photoGrapher.picUrl ?
           (
            <div className='d-flex justify-content-center my-3'>
            {mediaPreview ? (
               <Image
                  className='m-auto'
                  preview={false}
                  height={100}
                  width={100}
                  src={mediaPreview}
               />
            ) : (
               <Image
                  className='m-auto'
                  preview={false}
                  height={100}
                  width={100}
                  src={photoGrapher ? photoGrapher.picUrl.url : null}
               />
            )}
         </div>
           ) : (
            null
           )
          
        }
             <input
                hidden
                id='upload'
                name='imgUpload'
                accept='image/*'
                type='file'
                onChange={handelChange}
             />
             <label
                style={{
                   backgroundColor: '#cb8400',
                   color: 'white',
                   padding: '1rem',
                   fontSize: '16px',
                   borderRadius: '1rem',
                   margin: '1rem',
                   cursor: 'pointer',
                }}
                for='upload'>
                update profile picture{' '}
             </label>
  
             <Form
                form={form}
                name='basic'
                labelCol={{
                   span: 8,
                }}
                wrapperCol={{
                   span: 16,
                }}
                initialValues={{
                   name: photoGrapher.name,
                   bio: photoGrapher.bio,
                   email: photoGrapher.email,
                   tel: photoGrapher.tel,
                }}
                onFinish={onFinish}
                autoComplete='off'>
                <Form.Item
                   label='name'
                   name='name'
                   rules={[
                      {
                         required: true,
                         message: 'Please input your username!',
                      },
                   ]}>
                   <Input />
                </Form.Item>
  
                <Form.Item
                   label='bio'
                   name='bio'
                   rules={[
                      {
                         required: true,
                         message: 'Please input your bio!',
                      },
                   ]}>
                   <Input />
                </Form.Item>
  
                <Form.Item
                   label='email'
                   name='email'
                   rules={[
                      {
                         required: true,
                         message: 'Please input your email!',
                      },
                   ]}>
                   <Input />
                </Form.Item>
  
                <Form.Item
                   label='tel'
                   name='tel'
                   rules={[
                      {
                         required: true,
                         message: 'Please input your tel!',
                      },
                   ]}>
                   <Input />
                </Form.Item>
  
                <Form.Item
                   wrapperCol={{
                      offset: 8,
                      span: 16,
                   }}>
                   <Button block type='primary' htmlType='submit'>
                      Submit
                   </Button>
                </Form.Item>
             </Form>
          </Col>
          <Col md={12} lg={12} xs={24} sm={24}>
             {articles.length >= 1 && (
                <List
                   itemLayout='vertical'
                   size='large'
                   dataSource={articles}
                   footer={
                      <div>
                         <hr />
                      </div>
                   }
                   renderItem={(item) => (
                      <List.Item
                         key={item.title}
                         extra={
                            <img width={200} alt='logo' src={item.cover} />
                         }>
                         <List.Item.Meta
                            title={<a href={item.href}>{item.title}</a>}
                            description={item.short_des}
                         />
                         หมวดหมู่ {item.category}
                         <br />
                         {item.createdAt.toString().substring(0, 10)}
                      </List.Item>
                   )}
                />
             )}
          </Col>
       </Row>
      )}
     
     </>
       ) }
      </DefaultLayout>
   );
};

export default PhotoGrapherDetail
;
