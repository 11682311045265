import { Avatar } from 'antd';
import React, { useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import DefaultLayout from '../components/DefaultLayout ';

const Article = () => {
   const html = useRef(null);
   const navigate = useNavigate()

   const location = useLocation();
   const { state } = location;

   const author = state.record.author
   let photoGrapher = null
   if(state.record.photoGrapher) photoGrapher = state.record.photoGrapher

   useEffect(() => {
      html.current.innerHTML = state.record.html_content;
   }, []);



   return (
      <DefaultLayout>
       <>
       <Container>
       <div>
                 {/*  AUTHOR SECTION  */}
                 <h4 style={{ textAlign: 'left' }}> Author </h4>
                           <div className='blog_author'>
                              <div className='blog_author_avatar'>
                                 <Avatar
                                    size={128}
                                    src={author.picUrl}
                                 />
                              </div>
                              <div className='blog_author_info'>
                                 <div className='blog_author_info_1'>
                                    <div
                                       className='blog_author_username'
                                       onClick={() =>
                                          navigate(
                                             '/admin/authorprofile',
                                             {
                                                state: {
                                                   record:
                                                   author._id,
                                                },
                                             }
                                          )
                                       }>
                                       {author?.name}
                                    </div>
                                 </div>
                                 <div className='blog_author_info_2'>
                                    <div className='blog_readTime'>
                                       {author?.bio}
                                    </div>
                                 </div>
                              </div>
                           </div>
         </div>
         <div>
            <br /><br />
         {state.record.photoGrapher ? (
                              <>
                                 <h4 style={{ textAlign: 'left' }}>
                                    {' '}
                                    PhotoGrapher{' '}
                                 </h4>
                                 <div className='blog_author'>
                                    <div className='blog_author_avatar'>
                                       <Avatar
                                          size={128}
                                          src={
                                             photoGrapher?.picUrl.url
                                          }
                                       />
                                    </div>
                                    <div className='blog_author_info'>
                                       <div className='blog_author_info_1'>
                                          <div
                                             className='blog_author_username'
                                             onClick={() =>
                                                navigate(
                                                   '/admin/photoGrapherDetail',
                                                   {
                                                      state: {
                                                               record: photoGrapher._id,
                                                      },
                                                   }
                                                )
                                             }>
                                             {photoGrapher?.name}
                                          </div>
                                       </div>
                                       <div className='blog_author_info_2'>
                                          <div className='blog_readTime'>
                                             {photoGrapher?.bio}
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </>
                           ) : null}
         </div>
       </Container>
       </>
         <br />
         <br />
         <div className='html_content' ref={html}>
            <div
               style={{
                  width: '100vh',
                  height: '200px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
               }}>
               123
            </div>
         </div>
      </DefaultLayout>
   );
};

export default Article;
