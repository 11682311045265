import { Button, Card, Col, Form, Input, Modal, Row } from 'antd';

import React, { useEffect, useState } from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import {
   createAboutUs,
   deleteAbout,
   getAboutUs,
   updateAboutUs,
} from '../../features/aboutUs/aboutUsSlice';
import DefaultLayout from '../components/DefaultLayout ';
const { TextArea } = Input;
const AboutPage = () => {
   const [modalOpen, setModalOpen] = useState(false);
   const [editData, setEditData] = useState(null);
   const dispatch = useDispatch();

   const { aboutUss } = useSelector((state) => state.aboutUs);

   useEffect(() => {
      dispatch(getAboutUs());
   }, []);

   const onFinish = (values) => {
      if (editData === null) {
         dispatch(createAboutUs(values));
         window.location.reload();
      } else {
         values._id = editData._id;

         dispatch(updateAboutUs(values));
         window.location.reload();
      }
   };

   return (
      <DefaultLayout>
         <div>
            <div>
               <Button className='btn-primary' onClick={() => setModalOpen(true)}> เพิ่มข้อมูล </Button>
            </div>
            <br />
            <div>
               <Row gutter={[26, 26]}>
                  {aboutUss.map((e) => {
                     return (
                        <Col span={8}>
                           <Card
                              title={e.title}
                              hoverable
                              actions={[
                                 <MdEdit
                                    style={{ color: '#ff9a3c' }}
                                    onClick={() => {
                                       setEditData(e);
                                       setModalOpen(true);
                                    }}
                                 />,
                                 <MdDelete
                                    onClick={() => {
                                       dispatch(deleteAbout(e._id));
                                       window.location.reload();
                                    }}
                                    key='delete'
                                 />,
                              ]}
                              bordered={false}>
                              {e.description}
                           </Card>
                        </Col>
                     );
                  })}
               </Row>
            </div>
         </div>
         {modalOpen && (
            <Modal
               title={`${editData !== null ? 'แก้ไขข้อมูล' : 'เพิ่มข้อมูล'}`}
               visible={modalOpen}
               footer={false}
               onCancel={() => {
                  setModalOpen(false);
                  setEditData(null);
               }}>
               <Form
                  layout='vertical'
                  initialValues={editData}
                  onFinish={onFinish}>
                  <Form.Item name='title' label='หัวข้อ'>
                     <Input />
                  </Form.Item>

                  <Form.Item name='description' label='คำอธิบาย'>
                     <TextArea />
                  </Form.Item>

                  <div className='d-flex justify-content-end'>
                     <Button className='btn-primary' htmlType='submit' type='primary'>
                        {' '}
                        บันทึก
                     </Button>
                  </div>
               </Form>
            </Modal>
         )}
      </DefaultLayout>
   );
};

export default AboutPage;
