import axios from "axios";
import Resizer from "react-image-file-resizer";

export const uploadPic = async (media) => {
  try {
    const form = new FormData();
    form.append("file", media);
    form.append("upload_preset", "Field_Feel");
    form.append("cloud_name", "dtyx5ssnn");

    const res = await axios.post(
      "https://api.cloudinary.com/v1_1/dtyx5ssnn/image/upload",
      form
    );
    return res.data.secure_url;
  } catch (error) {
    return;
  }
};

export const uploadImage = async (file) => {
  const fdata = new FormData();
  console.log("size--->", file.size, "bytes");
  fdata.append("file", file);
  fdata.append("cloud_name", "dtyx5ssnn");
  fdata.append("upload_preset", "Field_Feel"); //may be profile image

  console.log(file);

  const config = {
    onUploadProgress: (p) => {
      const progress = p.loaded / p.total;

      console.log("percentage upload-->", progress * 100);
    },
  };

  const { data } = await axios.post(
    "https://api.cloudinary.com/v1_1/dtyx5ssnn/image/upload",
    fdata,
    config
  );

  //toast.dismiss(toastIdav);
  console.log(data);
  return data.secure_url;
};
