import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import photoGrapherService from './photoGrapherService';

const initialState = {
   photoGraphers: [],
   photoGrapher: {},
   isError: false,
   isSuccess: false,
   isLoading: false,
   message: '',
};

export const createPhotoGrahper = createAsyncThunk(
   'photoGrahper/create',
   async ({ payload, callback }, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token;

         return await photoGrapherService.createPhotoGrahper(
            { payload, callback },
            token
         );
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();

         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const updatePhotoGrapher = createAsyncThunk(
   'photoGrapher/photoGrahper',
   async (data, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user;
         return await photoGrapherService.updatePhotoGrapher(data, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getPhotoGrapher = createAsyncThunk(
   'photoGrapher/getPhotoGrapher',
   async (_, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token;
         return await photoGrapherService.getPhotoGrapher(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         console.log(error.response.status);
         if (error.response.status === 401) {
            localStorage.removeItem('userInfo');
         }

         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getPhotoGrapherDetail = createAsyncThunk(
   'photoGrapher/getPhotoGrapherDetail',
   async (id, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token;
         return await photoGrapherService.getPhotoGrapherDetail(id,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         console.log(error.response.status);
         if (error.response.status === 401) {
            localStorage.removeItem('userInfo');
         }

         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const deletePhotoGrapher = createAsyncThunk(
   'photoGrapher/delete',
   async (id, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token;
         return await photoGrapherService.deletePhotoGrapher(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const photoGrapherSlice = createSlice({
   name: 'photoGrapher',
   initialState,
   reducers: {
      reset: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(createPhotoGrahper.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(createPhotoGrahper.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.photoGrapher = action.payload;
         })
         .addCase(createPhotoGrahper.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getPhotoGrapher.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getPhotoGrapher.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.photoGraphers.push(action.payload);
         })
         .addCase(getPhotoGrapher.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getPhotoGrapherDetail.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getPhotoGrapherDetail.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.photoGrapher = (action.payload);
         })
         .addCase(getPhotoGrapherDetail.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(updatePhotoGrapher.pending, (state, action) => {
            state.isLoading = true;
         })
         .addCase(updatePhotoGrapher.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.photoGrapher = action.payload;
         })
         .addCase(updatePhotoGrapher.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(deletePhotoGrapher.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(deletePhotoGrapher.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.photoGraphers = state.photoGraphers.filter(
               (photoGrahper) => photoGrahper._id !== action.payload.id
            );
         })
         .addCase(deletePhotoGrapher.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         });
   },
});

export const { reset } = photoGrapherSlice.actions;
export default photoGrapherSlice.reducer;
