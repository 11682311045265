import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import departmentService from './departmentService';

const initialState = {
   departments: [],
   department: {},
   isError: false,
   isSuccess: false,
   isLoading: false,
   message: '',
};

export const createDepartment = createAsyncThunk(
   'department/create',
   async ({ payload, callback }, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token;

         return await departmentService.createDepartment(
            { payload, callback },
            token
         );
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         console.log(message);
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getDepartment = createAsyncThunk(
   'department/get',
   async (_, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token;
         return await departmentService.getDepartment(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// Update Department
export const updateDepartment = createAsyncThunk(
   'department/update',
   async (departmentData, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user;
         return await departmentService.updateDepartment(departmentData, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const departmentSlice = createSlice({
   name: 'department',
   initialState,
   reducers: {
      reset: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(getDepartment.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getDepartment.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.departments.push(action.payload);
         })
         .addCase(getDepartment.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(createDepartment.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(createDepartment.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.departments = action.payload;
         })
         .addCase(createDepartment.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(updateDepartment.pending, (state, action) => {
            state.isLoading = true;
         })
         .addCase(updateDepartment.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.department = action.payload;
         })
         .addCase(updateDepartment.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         });
   },
});

export const { reset } = departmentSlice.actions;
export default departmentSlice.reducer;
