import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, Modal, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import DefaultLayout from '../components/DefaultLayout ';
import {
   getCategory,
   createCategory,
   reset,
   deleteCategory,
   updateCategory,
} from '../../features/category/categorySlice';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import { MdDeleteForever, MdModeEdit } from 'react-icons/md';
import { RiNumbersFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';

const CategoryPage = () => {
   const dispatch = useDispatch();
   const [isSuccess, setIsSuccess] = useState(false);
   const [modalOpen, setModalOpen] = useState(false);
   const [modalEditOpen, setModalEditOpen] = useState(false);
   const [nameCategory, setNameCategory] = useState('');
   const cid = React.useRef();

   const [loadigPage, setLoadingPage] = useState(true);

   const { categories, isLoading, isError, message } = useSelector(
      (state) => state.category
   );

   let categories2 = [];
   if (categories) {
      categories2 = categories.flat();
   }

   useEffect(() => {
      if (loadigPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }
      dispatch(getCategory());

      return () => {
         dispatch(reset());
      };
   }, []);

   const onFinish = (values) => {
      if (!modalEditOpen) {
         const callback = (res) => {
         
            if (res) {
               setIsSuccess(true);
               setModalOpen(false);
               toast.success('เพิ่มข้อมูลสำเร็จ', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
               });
            } else {
           
               toast.error('ไม่สำเร็จ');
            }
         };

         dispatch(createCategory({ payload: values, callback: callback }));
         window.location.reload();
      } else {
         values._id = cid.current;
         dispatch(updateCategory(values));
         window.location.reload();
      }
   };

   const handleDelete = (id) => {
      
      if (window.confirm('Are you sure')) {
         const callback = (res) => {
            if (res) {
               setIsSuccess(true);
               toast.success('ลบข้อมูลสำร็จ', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
               });
            } else {
               toast.error('ไม่สำเร็จ');
            }
         };

         dispatch(deleteCategory({ payload: id, callback: callback }));
      }
   };

   const handleEdit = (value) => {
      setModalEditOpen(true);
      cid.current = value._id;
      setNameCategory(value.name);
   };

   return (
      <DefaultLayout>
         {isError &&
            toast.error(message, {
               position: 'top-right',
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: 'light',
            })}
         {loadigPage ? (
            <Loading />
         ) : (
            <>
               <div className='d-flex justify-content-between'>
                  <h3> รายการหมวดหมู่ </h3>
                  <Button type='primary' onClick={() => setModalOpen(true)}>
                     {' '}
                     สร้างหมวดหมู่{' '}
                  </Button>
               </div>
               <Row gutter={[26, 26]}>
                  {categories2.map((category) => {
                     return (
                        <Col xs={24} md={12} xl={8}>
                           <Link to={`/admin/article/${category.name}`}>
                              <Card
                                 className='text-capitalize'
                                 title={category.name}
                                 hoverable
                                 bordered={false}
                                 actions={[
                                    <MdDeleteForever
                                       style={{ fontSize: '32px !important' }}
                                       key='delete'
                                       onClick={() =>
                                          handleDelete(category._id)
                                       }
                                    />,
                                    <MdModeEdit
                                       key='edit'
                                       onClick={() => handleEdit(category)}
                                    />,
                                 ]}></Card>
                           </Link>
                        </Col>
                     );
                  })}
               </Row>
            </>
         )}
         {modalOpen && (
            <Modal
               title='สร้างหมวดหมู่'
               visible={modalOpen}
               footer={false}
               onCancel={() => {
                  setModalOpen(false);
               }}>
               <Form layout='vertical' onFinish={onFinish}>
                  <Form.Item name='name' label='หมวดหมู่'>
                     <Input />
                  </Form.Item>

                  <div className='d-flex justify-content-end'>
                     <Button htmlType='submit' type='primary'>
                        {' '}
                        SAVE
                     </Button>
                  </div>
               </Form>
            </Modal>
         )}
         {modalEditOpen && (
            <Modal
               title='แก้ไข'
               visible={modalEditOpen}
               footer={false}
               onCancel={() => {
                  setModalEditOpen(false);
               }}>
               <Form layout='vertical' onFinish={onFinish}>
                  <Form.Item name='name' label='หมวดหมู่'>
                     <Input placeholder={nameCategory} />
                  </Form.Item>

                  <div className='d-flex justify-content-end'>
                     <Button htmlType='submit' type='primary'>
                        {' '}
                        SAVE
                     </Button>
                  </div>
               </Form>
            </Modal>
         )}
      </DefaultLayout>
   );
};

export default CategoryPage;
