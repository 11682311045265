import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Loading from '../components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Row, Tag } from 'antd';
import { getEmployees } from '../features/employee/employeeSlice';
import styled from 'styled-components';
import { getAboutUs } from '../features/aboutUs/aboutUsSlice';
const { Meta } = Card;

const AboutUs = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const [loadigPage, setLoadingPage] = useState(true);

   const {
      employees: employeesData,
      isLoading,
      isError,
      message,
   } = useSelector((state) => state.employee);
   const { aboutUss } = useSelector((state) => state.aboutUs);

   let employees = [];
   if (employeesData) employees = employeesData.flat();

   let founder = employees.filter((e) => e.department === 'ผู้ก่อตั้ง');
   let admin = employees.filter((e) => e.department === 'ธุรการ');
   let production = employees.filter(
      (e) => e.department === 'ฝ่ายผลิตและถ่ายทำ'
   );

   useEffect(() => {
      if (loadigPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }

      dispatch(getAboutUs());
      dispatch(getEmployees());
   }, [dispatch]);

   return (
      <>
         <Header />
         {isLoading ? (
            <Loading />
         ) : (
            <>
               <br />
               <br />
               <Container>
                  <div className='header-name mt-5 mb-5'>
                     <HeaderName> เกี่ยวกับเรา </HeaderName>
                  </div>
                  <hr />
                  <br />
                  <div>
                     {aboutUss.map((e) => {
                        return (
                           <div>
                              <h3 className='text-start'>{e.title}</h3>
                              <p
                                 style={{
                                    marginLeft: '2.5rem',
                                    marginBottom: '2.5rem',
                                    wordBreak: 'break-all'
                                 }}>
                                 {' '}
                                 {e.description}{' '}
                              </p>
                           </div>
                        );
                     })}
                  </div>
                  {/* <div
                     className='site-card-wrapper'
                     style={{ marginBottom: '200px' }}>
                     <h1 style={{ color: '#2e3c2f' }}> ผู้ก่อตั้ง</h1>
                     <Row gutter={[20, 50]}>
                        {employees.map((f) => {
                           return (
                              <Col key={f._id} xs={24} md={12} xl={8}>
                                 <Card
                                    hoverable
                                    cover={
                                       <img alt='img 1' src={f.profilePicUrl} />
                                    }>
                                    <Meta
                                       style={{ whiteSpace: 'normal' }}
                                       title={
                                          <>
                                             <p
                                                className='text-uppercase'
                                                style={{ color: '#345c64' }}>
                                                <p>{f.position}</p>
                                             </p>
                                             <p>{f.name}</p>
                                          </>
                                       }
                                    />
                                    <p>
                                       {f.cv > 50
                                          ? `${f.cv.substring(0, 120)}...`
                                          : f.cv}
                                    </p>
                                    <Tag style={{ marginRight: '100%' }}>
                                       {' '}
                                       {f.department}{' '}
                                    </Tag>
                                 
                                 </Card>
                              </Col>
                           );
                        })}
                     </Row>
                  </div> */}
                  {/* <div>
                     <div
                        className='site-card-wrapper'
                        style={{ marginBottom: '200px' }}>
                        <h1 style={{ color: '#2e3c2f' }}> ผู้ก่อตั้ง</h1>
                        <Row gutter={[20, 50]}>
                           {founder.map((f) => {
                              return (
                                 <Col key={f._id} xs={24} md={12} xl={8}>
                                    <Card
                                       hoverable
                                       cover={
                                          <img
                                             alt='img 1'
                                             src={f.profilePicUrl}
                                          />
                                       }>
                                       <Meta
                                          style={{ whiteSpace: 'normal' }}
                                          title={
                                             <>
                                                <p
                                                   className='text-uppercase'
                                                   style={{ color: '#345c64' }}>
                                                   <p>{f.position}</p>
                                                </p>
                                                <p>{f.name}</p>
                                             </>
                                          }
                                       />
                                       <p>
                                          {f.cv > 50
                                             ? `${f.cv.substring(0, 120)}...`
                                             : f.cv}
                                       </p>
                                    </Card>
                                 </Col>
                              );
                           })}
                        </Row>
                     </div>
                     <hr />
                     <div
                        className='site-card-wrapper'
                        style={{ marginBottom: '200px' }}>
                        <h1 style={{ color: '#2e3c2f' }}> ฝ่ายผลิตและถ่ายทำ</h1>
                        <Row gutter={[20, 50]}>
                           {production.map((f) => {
                              return (
                                 <Col key={f._id} xs={24} md={12} xl={8}>
                                    <Card
                                       hoverable
                                       cover={
                                          <img
                                             alt='img 1'
                                             src={f.profilePicUrl}
                                          />
                                       }>
                                       <Meta
                                          style={{ whiteSpace: 'normal' }}
                                          title={
                                             <>
                                                <p
                                                   className='text-uppercase'
                                                   style={{ color: '#345c64' }}>
                                                   <p>{f.position}</p>
                                                </p>
                                                <p>{f.name}</p>
                                             </>
                                          }
                                       />
                                       <p>
                                          {f.cv > 50
                                             ? `${f.cv.substring(0, 120)}...`
                                             : f.cv}
                                       </p>
                                    </Card>
                                 </Col>
                              );
                           })}
                        </Row>
                     </div>
                     <hr />
                     <div
                        className='site-card-wrapper'
                        style={{ marginBottom: '200px' }}>
                        <h1 style={{ color: '#2e3c2f' }}> ฝ่ายธุรการ</h1>
                        <Row gutter={[20, 50]}>
                           {admin.map((f) => {
                              return (
                                 <Col key={f._id} xs={24} md={12} xl={8}>
                                    <Card
                                       hoverable
                                       cover={
                                          <img
                                             alt='img 1'
                                             src={f.profilePicUrl}
                                          />
                                       }>
                                       <Meta
                                          style={{ whiteSpace: 'normal' }}
                                          title={
                                             <>
                                                <p
                                                   className='text-uppercase'
                                                   style={{ color: '#345c64' }}>
                                                   <p>{f.position}</p>
                                                </p>
                                                <p>{f.name}</p>
                                             </>
                                          }
                                       />
                                       <p>
                                          {f.cv > 50
                                             ? `${f.cv.substring(0, 120)}...`
                                             : f.cv}
                                       </p>
                                    </Card>
                                 </Col>
                              );
                           })}
                        </Row>
                     </div>
                  </div> */}
               </Container>
               <br />
               <br />
               <Footer />
            </>
         )}
      </>
   );
};

const HeaderName = styled.h1`
   color: #345c64;
   font-size: 46px;
`;

export default AboutUs;
