import { useEffect, useState } from "react";
import {
  BsFacebook,
  BsTwitter,
  BsYoutube,
  BsInstagram,
  BsSearch,
} from "react-icons/bs";
import { FaBars, FaWindowClose } from "react-icons/fa";
import { AiOutlineMenu } from "react-icons/ai";
import { MdAdminPanelSettings } from "react-icons/md";
import { BiSearch } from "react-icons/bi";
import { useNavigate, Link } from "react-router-dom";
import {
  Button,
  Drawer,
  Radio,
  Space,
  Dropdown,
  Menu,
  Row,
  Col,
  Typography,
  Image,
  Modal,
} from "antd";
import "../resource/Header.css";
import logo from "../assets/logo.png";
import logo2 from "../assets/logo2.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../features/category/categorySlice";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";
import Meta from "./Meta";
import styled from "styled-components";

const Header = ({ openModal }) => {
  const dispatch = useDispatch();

  const [userInfo, setUserInfo] = useState(false);
  const [click, setClick] = useState(false);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("left");

  const [isToggle, setIsToggle] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { categories, isLoading, isError, message } = useSelector(
    (state) => state.category
  );
  const [screenWidth, setScreenWidth] = useState(null);
  const Desktop = useMediaQuery({ minWidth: 1025 });
  // const Tablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  // const Mobile = useMediaQuery({ maxWidth: 767 });
  const Tablet = useMediaQuery({ minWidth: 426, maxWidth: 1024 });
  const Mobile = useMediaQuery({ maxWidth: 425 });

  let categories2 = categories.flat();
  let cName = categories2.map((e) => e.name);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };

    dispatch(getCategory());
  }, [screenWidth]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onChange = (e) => {
    setPlacement(e.target.value);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleClick = () => {
    setClick(!click);

    if (isToggle) {
      setIsToggle(false);
    } else {
      setIsToggle(true);
    }
  };

  const logoutHandler = () => {};

  // const handleClick = () => {
  //    setClick(!click);
  //    console.log(click);
  // 👇️ toggle class on click
  // event.currentTarget.classList.toggle('collapse collapse-toggle2');

  // 👇️ add class on click
  // event.currentTarget.classList.add('collapse-toggle2');

  // 👇️ remove class on click
  // event.currentTarget.classList.remove('bg-salmon');
  // };

  const handleClickNav = (e) => {
    localStorage.setItem("nav", e);
  };

  const settings = {
    infinite: true,
    speed: 1200,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  // const Desktop = ({ children }) => {
  //    const isDesktop = useMediaQuery({ minWidth: 1024 });
  //    return isDesktop ? children : null;
  // };

  // const Tablet = ({ children }) => {
  //    const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  //    return isTablet ? children : null;
  // };

  // const Mobile = ({ children }) => {
  //    const isMobile = useMediaQuery({ maxWidth: 768 });
  //    return isMobile ? children : null;
  // };

  const title = 'Field - Feel ฟิลด์ - ฟิล พื้นที่การสื่อสารจากมุมมองท้องถิ่น"';
  const description =
    'ฟิล์ด - ฟิล มีเดีย (Field - Feel Co., Ltd.) ริเริ่มโดยกลุ่มนักเรียนสื่อ และนักเรียนมานุษยวิทยา ต้องการมีส่วนร่วมสื่อสารภายใต้แนวคิด "สื่อสาร สัมผัสสนาม ข้ามพรมแดน" เพื่อบอกเล่าและขับเคลื่อนประเด็นทางสังคม การเมือง และวัฒนธรรม ในระดับมหภาค ผ่านมุมมองจุลภาค ในแต่ละท้องถิ่น ตีแผ่ความรุนแรงเชิงโครงสร้าง ผ่านเรื่องราวสายตาของคนตัวเล็ก ๆ ฟิล์ด ฟิล มีเดีย มีเป้าหมายเพื่อพาผู้คนในสังคมก้าวข้าม ผ่านพรมแดนแห่งความรุนแรงที่แบ่งแยกเราไว้ ทั้งพรมแดนทางความคิด พรมเเดนทางเพศ พรมแดนทางวัฒนธรรม พรมแดนรัฐชาติ ฯลฯ จนเกิดเป็นสังคมที่ผนวกรวมทุกคนเข้าไว้ด้วยกัน บนพื้นฐานของความเข้าอกเข้าใจ';

  return (
    <header id="header" className="shadow-twilight">
      <nav className="container navbar2">
        <div>
          <Link
            onClick={() => localStorage.setItem("nav", " ")}
            to="/"
            className="nav-brand2 text-dark"
          >
            <Image src={logo2} width={50} preview={false} />
          </Link>
        </div>

        <button className="toggle-button2">
          {!isToggle ? (
            <span>
              <FaBars onClick={handleClick} />
            </span>
          ) : (
            <span>
              <FaWindowClose onClick={handleClick} />
            </span>
          )}
        </button>
        {Desktop && (
          <>
            <div>
              <ul className="text-capitalize">
                <Slider {...settings}>
                  <Link
                    onClick={() => handleClickNav(cName[0])}
                    to={`/category/${cName[0]}`}
                    className="nav-link2"
                  >
                    {cName[0]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[1])}
                    to={`/category/${cName[1]}`}
                    className="nav-link2"
                  >
                    {cName[1]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[2])}
                    to={`/category/${cName[2]}`}
                    className="nav-link2"
                  >
                    {cName[2]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[3])}
                    to={`/category/${cName[3]}`}
                    className="nav-link2"
                  >
                    {cName[3]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[4])}
                    to={`/category/${cName[4]}`}
                    className="nav-link2"
                  >
                    {cName[4]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[5])}
                    to={`/category/${cName[5]}`}
                    className="nav-link2"
                  >
                    {cName[5]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[6])}
                    to={`/category/${cName[6]}`}
                    className="nav-link2"
                  >
                    {cName[6]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[7])}
                    to={`/category/${cName[7]}`}
                    className="nav-link2"
                  >
                    {cName[7]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[8])}
                    to={`/category/${cName[8]}`}
                    className="nav-link2"
                  >
                    {cName[8]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[9])}
                    to={`/category/${cName[9]}`}
                    className="nav-link2"
                  >
                    {cName[9]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[10])}
                    to={`/category/${cName[10]}`}
                    className="nav-link2"
                  >
                    {cName[10]}
                  </Link>
                  <Link to="/aboutus" className="nav-link2">
                    About Us
                  </Link>
                </Slider>
              </ul>
            </div>
          </>
        )}

        {Tablet && (
          <>
            <div>
              <ul className="navbar-nav2 text-capitalize">
                <Slider {...settings}>
                  <Link
                    onClick={() => handleClickNav(cName[0])}
                    to={`/category/${cName[0]}`}
                    className="nav-link2"
                  >
                    {cName[0]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[1])}
                    to={`/category/${cName[1]}`}
                    className="nav-link2"
                  >
                    {cName[1]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[2])}
                    to={`/category/${cName[2]}`}
                    className="nav-link2"
                  >
                    {cName[2]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[3])}
                    to={`/category/${cName[3]}`}
                    className="nav-link2"
                  >
                    {cName[3]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[4])}
                    to={`/category/${cName[4]}`}
                    className="nav-link2"
                  >
                    {cName[4]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[5])}
                    to={`/category/${cName[5]}`}
                    className="nav-link2"
                  >
                    {cName[5]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[6])}
                    to={`/category/${cName[6]}`}
                    className="nav-link2"
                  >
                    {cName[6]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[7])}
                    to={`/category/${cName[7]}`}
                    className="nav-link2"
                  >
                    {cName[7]}
                  </Link>

                  <Link
                    onClick={() => handleClickNav(cName[8])}
                    to={`/category/${cName[8]}`}
                    className="nav-link2"
                  >
                    {cName[8]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[9])}
                    to={`/category/${cName[9]}`}
                    className="nav-link2"
                  >
                    {cName[9]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[10])}
                    to={`/category/${cName[10]}`}
                    className="nav-link2"
                  >
                    {cName[10]}
                  </Link>
                  <Link to="/aboutus" className="nav-link2">
                    About Us
                  </Link>
                </Slider>
              </ul>
            </div>
          </>
        )}

        {Mobile && (
          <>
            <div className={click ? "collapse2 collapse-toggle2" : "collapse2"}>
              <ul className="navbar-nav2 text-capitalize">
                <Link
                  onClick={() => handleClickNav(cName[0])}
                  to={`/category/${cName[0]}`}
                  className="nav-link2"
                >
                  {cName[0]}
                </Link>
                <Link
                  onClick={() => handleClickNav(cName[1])}
                  to={`/category/${cName[1]}`}
                  className="nav-link2"
                >
                  {cName[1]}
                </Link>
                <Link
                  onClick={() => handleClickNav(cName[2])}
                  to={`/category/${cName[2]}`}
                  className="nav-link2"
                >
                  {cName[2]}
                </Link>
                <Link
                  onClick={() => handleClickNav(cName[3])}
                  to={`/category/${cName[3]}`}
                  className="nav-link2"
                >
                  {cName[3]}
                </Link>
                <Link
                  onClick={() => handleClickNav(cName[4])}
                  to={`/category/${cName[4]}`}
                  className="nav-link2"
                >
                  {cName[4]}
                </Link>
                <Link
                  onClick={() => handleClickNav(cName[5])}
                  to={`/category/${cName[5]}`}
                  className="nav-link2"
                >
                  {cName[5]}
                </Link>
                <Link
                  onClick={() => handleClickNav(cName[6])}
                  to={`/category/${cName[6]}`}
                  className="nav-link2"
                >
                  {cName[6]}
                </Link>
                <Link
                  onClick={() => handleClickNav(cName[7])}
                  to={`/category/${cName[7]}`}
                  className="nav-link2"
                >
                  {cName[7]}
                </Link>

                <Link
                  onClick={() => handleClickNav(cName[8])}
                  to={`/category/${cName[8]}`}
                  className="nav-link2"
                >
                  {cName[8]}
                </Link>
                <Link
                  onClick={() => handleClickNav(cName[9])}
                  to={`/category/${cName[9]}`}
                  className="nav-link2"
                >
                  {cName[9]}
                </Link>
                <Link
                  onClick={() => handleClickNav(cName[10])}
                  to={`/category/${cName[10]}`}
                  className="nav-link2"
                >
                  {cName[10]}
                </Link>
                <Link to="/aboutus" className="nav-link2">
                  About Us
                </Link>
              </ul>
            </div>
          </>
        )}

        <div className={click ? "collapse2 collapse-toggle2" : "collapse2"}>
          <ul className="navbar-nav2">
            <div className="search-box2">
              <BsSearch style={{ cursor: "pointer" }} onClick={openModal} />
            </div>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
