import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import employeeService from './employeeService';

const initialState = {
   employees: [],
   employee: {},
   isError: false,
   isSuccess: false,
   isLoading: false,
   isDeleteSuccess: false,
   isDeleteError: false,
   isDeleteMessage: false,
   isCreateSuccess: false,
   isCreateError: false,
   isCreateMessage: false,
   isUpdateSuccess: false,
   isUpdateError: false,
   isUpdateMessage: false,
   message: '',
};

// Create new employee
export const createEmployee = createAsyncThunk(
   'employee/cremployeeeate',
   async (employeeData, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token;

         return await employeeService.createEmployee(employeeData, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         console.log(message);
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// Get employees
export const getEmployees = createAsyncThunk(
   'employee/getEmployees',
   async (_, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token;
         return await employeeService.getEmployees(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const deleteEmployee = createAsyncThunk(
   'employee/delete',
   async (id, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token;
         return await employeeService.deleteEmployee(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const updateEmployee = createAsyncThunk(
   'employee/update',
   async (authorData, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user;
         return await employeeService.updateEmployee(authorData, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const employeeSlice = createSlice({
   name: 'employee',
   initialState,
   reducers: {
      reset: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(createEmployee.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(createEmployee.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.employee = action.payload;
         })
         .addCase(createEmployee.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getEmployees.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getEmployees.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.employees.push(action.payload);
         })
         .addCase(getEmployees.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(updateEmployee.pending, (state, action) => {
            state.isLoading = true;
         })
         .addCase(updateEmployee.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.employee = action.payload;
         })
         .addCase(updateEmployee.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(deleteEmployee.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(deleteEmployee.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.employees = state.employees.filter(
               (employee) => employee._id !== action.payload.id
            );
         })
         .addCase(deleteEmployee.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         });
   },
});

export const { reset } = employeeSlice.actions;
export default employeeSlice.reducer;
