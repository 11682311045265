import { Button, message, Space, Switch, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import DefaultLayout from '../components/DefaultLayout ';
import {
   deleteArticle,
   getArticles,
   getArticlesByCategory,
   reset,
   updateBannerArticle,
} from '../../features/article/articleSlice';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { BsCheckLg } from 'react-icons/bs';
import { MdOutlineClose } from 'react-icons/md';

const Articles2 = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const params = useParams();
   const [loadigPage, setLoadingPage] = useState(true);
   const [isSuccess, setIsSuccess] = useState(false);

   const { user } = useSelector((state) => state.auth);
   const {
      articles,
      isLoading,
      isSuccess: success,
      isError,
      message: articleMessage,
   } = useSelector((state) => state.article);

   const deleteHanler = (record) => {
      if (window.confirm('Are you sure')) {
         const callback = (res) => {
            if (res) {
               setIsSuccess(true);
               message.success('article deleted');
            } else {
               toast.error('ไม่สำเร็จ');
            }
         };

         const aid = record._id;

         dispatch(deleteArticle({ payload: aid, callback: callback }));
      }
   };

   const handleSwitchChange = (record) => {
      dispatch(updateBannerArticle(record._id));
      window.location.reload();
   };

   useEffect(() => {
      if (loadigPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }

      if (isError) {
        
      }

      if (!user) {
         navigate('/login');
      }

      dispatch(getArticlesByCategory(params.c));

      return () => {
         dispatch(reset());
      };
   }, [dispatch, isSuccess]);

   const EditStory = (record) => {
      if (record) {
         localStorage.setItem('content', record.html_content);
         localStorage.setItem('content-author', record.author.name);
         localStorage.setItem('content-title', record.title);
         localStorage.setItem('content-short_des', record.short_des);
         localStorage.setItem('content-category', record.category);
         //     localStorage.setItem('content', record);
         setTimeout(() => {
            navigate(`/editArticle/${record._id}`);
         }, 100);
      }
   };

   const columns = [
      {
         title: 'ชื่อบทความ',
         dataIndex: 'title',
         key: 'title',
         width: '20%',
         render: (value, record) => (
            <a
               onClick={() =>
                  navigate('/admin/article', {
                     state: {
                        record: record,
                     },
                  })
               }>
               {value.length > 25 ? `${value.substring(0, 25)} ...` : value}
            </a>
         ),
      },
      {
         title: 'Image',
         dataIndex: 'cover',
         render: (cover, record) => (
            <img
               src={cover}
               alt=''
               style={{ objectFit: 'fill' }}
               height='100'
               width='100'
            />
         ),
      },
      {
         title: 'ผู้เขียน',
         dataIndex: 'author',
         key: 'author',
         render: (record) => <p> {record.name} </p>,
      },
      {
         title: 'คำอธิบาย',
         dataIndex: 'short_des',
         key: 'short_des',
         width: '20%',
         render: (record) => (
            <div>
               {' '}
               {record.length > 95 ? `${record.substring(0, 95)} ...` : record}
            </div>
         ),
      },
      {
         title: 'เวลาในการอ่าน',
         dataIndex: 'readingTime',
         key: 'readingTime',
      },
      {
         title: 'category',
         dataIndex: 'category',
         key: 'category',
      },
      {
         title: 'วันที่',
         dataIndex: 'createdAt',
         render: (createdAt) => (
            <div> {createdAt.toString().substring(0, 10)} </div>
         ),
      },
      {
         title: 'แบนเนอร์',
         dataIndex: 'isBanner',
         key: 'isBanner',
         render: (e, record) => (
            <Switch
               onChange={() => handleSwitchChange(record)}
               defaultChecked={e}
               checkedChildren={<BsCheckLg />}
               unCheckedChildren={<MdOutlineClose />}
            />
         ),
      },
      {
         title: 'Action',
         key: 'action',
         render: (_, record) => (
            <Space size='middle'>
               <b
                  style={{ cursor: 'pointer' }}
                  onClick={() => EditStory(record)}>
                  Edit{' '}
               </b>
               <b
                  style={{ cursor: 'pointer' }}
                  onClick={() => deleteHanler(record)}>
                  Delete
               </b>
            </Space>
         ),
      },
   ];

   return (
      <DefaultLayout>
         {loadigPage ? (
            <Loading />
         ) : (
            <>
               <div className='d-flex justify-content-between'>
                  <h3> รายการบทความทั้งหมด </h3>
                  <Button
                     type='primary'
                     onClick={() => navigate('/createArticle')}>
                     {' '}
                     สร้างบทความ{' '}
                  </Button>
               </div>
               <Table columns={columns} dataSource={articles} />;
            </>
         )}
      </DefaultLayout>
   );
};

export default Articles2;
