import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { HelmetProvider } from "react-helmet-async";

import Header from "./components/Header";
import HomePage from "./pages/HomePage";
import BookScreen from "./pages/BookScreen";
import ArticlePage from "./pages/ArticlePage";
import LocalScreen from "./pages/LocalScreen";
import LifeStyleScreen from "./pages/LifeStyleScreen";
import AuthorScreen from "./pages/AuthorScreen";
import GenderScreen from "./pages/GenderPage";
import PopcultureScreen from "./pages/PopculturePage";
import CategoryScreen from "./pages/CategoryScreen";

/*   ADMIN */
import Login from "./admin/page/Login";
import Home from "./admin/page/Home";
import Articles from "./admin/page/Articles";
import CategoryPage from "./admin/page/CategoryPage";
import CreateArticle from "./admin/page/CreateArticle";
import Article from "./admin/page/Article";
import LocalPage from "./admin/page/LocalPage";
import BokkPage from "./admin/page/BokkPage";
import LifeStylePage from "./admin/page/LifeStylePage";
import PopCulturePage from "./admin/page/PopCulturePage";
import GenderPage from "./admin/page/GenderPage";
import SocialMovement from "./admin/page/SocialMovement";
import AdminPage from "./admin/page/AdminPage";
import AuthorPage from "./admin/page/AuthorPage";
import AuthorProfilePage from "./admin/page/AuthorProfilePage";
import AdminProfile from "./admin/page/AdminProfile";
import ImageSilde from "./admin/page/ImageSilde";
import { ToastContainer } from "react-toastify";
import SocailMovementPage from "./pages/SocailMovementPage";
import EmployeePage from "./admin/page/EmployeePage";
import AboutUs from "./pages/AboutUs";
import DepartmentPage from "./admin/page/DepartmentPage";
import SearchPage from "./pages/SearchPage";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "./features/category/categorySlice";
import { useEffect } from "react";
import Articles2 from "./admin/page/Articles2";
import PhotoGrapherPage from "./admin/page/PhotoGrapherPage";
import AboutPage from "./admin/page/AboutPage";
import PhotoGrapherDetail from "./admin/page/PhotoGrapherDetail";
import EditArticleData from "./admin/page/EditArticleData";
import PhotoGrapherScreen from "./pages/PhotoGrapherScreen";
import ArticlesByTags from "./pages/ArticlesByTags";

function App() {
  const dispatch = useDispatch();

  const { categories, isLoading, isError, message } = useSelector(
    (state) => state.category
  );

  let categories2 = categories.flat();
  let cName = categories2.map((e) => e.name);

  let cName2 = localStorage.getItem("nav");

  useEffect(() => {
    dispatch(getCategory());
  }, []);

  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
      <div className="App">
        <ToastContainer />
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/:id" element={<ArticlePage />} />

            {/* ADMIN */}
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Home />{" "}
                </ProtectedRoute>
              }
            />
            <Route path="/login" element={<Login />} />
            <Route
              path="/articles"
              element={
                <ProtectedRoute>
                  <Articles />
                </ProtectedRoute>
              }
            />
            <Route path="/author/:name" element={<AuthorScreen />} />
            <Route
              path="/photographer/:name"
              element={<PhotoGrapherScreen />}
            />
            <Route path={`/category/:c`} element={<CategoryScreen />} />
            <Route path={`/tags/:tag`} element={<ArticlesByTags />} />
            <Route path="/aboutus" element={<AboutUs />} />
            {/* <Route
                  path='/category/lifestyle'
                  element={<LifeStyleScreen />}
               />
               <Route path='/category/book' element={<BookScreen />} />
               <Route
                  path='/category/socialmovement'
                  element={<SocailMovementPage />}
               />
               <Route path='/category/gender' element={<GenderScreen />} />
               <Route
                  path='/category/popculture'
                  element={<PopcultureScreen />}
               /> */}
            <Route path="/search/:text" element={<SearchPage />} />

            <Route
              path="/admin/admin"
              element={
                <ProtectedRoute>
                  <AdminPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/author"
              element={
                <ProtectedRoute>
                  <AuthorPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/photographer"
              element={
                <ProtectedRoute>
                  <PhotoGrapherPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/photographerDetail"
              element={
                <ProtectedRoute>
                  <PhotoGrapherDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/category"
              element={
                <ProtectedRoute>
                  <CategoryPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/department"
              element={
                <ProtectedRoute>
                  <DepartmentPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/admin/article"
              element={
                <ProtectedRoute>
                  <Article />
                </ProtectedRoute>
              }
            />
            <Route path={`/admin/article/:c`} element={<Articles2 />} />
            {/*
               <Route
                  path='/admin/book'
                  element={
                     <ProtectedRoute>
                        <BokkPage />
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='/admin/local'
                  element={
                     <ProtectedRoute>
                        <LocalPage />
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='/admin/gender'
                  element={
                     <ProtectedRoute>
                        <GenderPage />
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='/admin/popculture'
                  element={
                     <ProtectedRoute>
                        <PopCulturePage />
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='/admin/lifestyle'
                  element={
                     <ProtectedRoute>
                        <LifeStylePage />
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='/admin/social%20movement'
                  element={
                     <ProtectedRoute>
                        <SocialMovement />
                     </ProtectedRoute>
                  }
               /> */}
            <Route
              path="/createArticle"
              element={
                <ProtectedRoute>
                  <CreateArticle />
                </ProtectedRoute>
              }
            />
            <Route
              path="/editArticle/:aid"
              element={
                <ProtectedRoute>
                  <CreateArticle
                    key={window.location.pathname}
                    isEditing={true}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/editArticleData/:aid"
              element={
                <ProtectedRoute>
                  <EditArticleData />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/authorprofile"
              element={
                <ProtectedRoute>
                  <AuthorProfilePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/adminprofile"
              element={
                <ProtectedRoute>
                  <AdminProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/employee"
              element={
                <ProtectedRoute>
                  <EmployeePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/about"
              element={
                <ProtectedRoute>
                  <AboutPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/imagesilde"
              element={
                <ProtectedRoute>
                  <ImageSilde />
                </ProtectedRoute>
              }
            />
            {/* ADMIN */}
          </Routes>
        </Router>
      </div>
    </HelmetProvider>
  );
}

export default App;

export function ProtectedRoute({ children }) {
  if (localStorage.getItem("userInfo")) {
    return children;
  } else {
    return <Navigate to="/login" />;
  }
}
