import {
   Button,
   Form,
   Image,
   Input,
   Modal,
   Select,
   Space,
   Table,
   Tag,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
   createEmployee,
   deleteEmployee,
   getEmployees,
   reset,
   updateEmployee,
} from '../../features/employee/employeeSlice';
import styled from 'styled-components';
import DefaultLayout from '../components/DefaultLayout ';
import ImageDropDiv from '../components/ImageDropDiv';
import Resizer from 'react-image-file-resizer';
import { uploadImage, uploadPic } from '../../utils/connect';
import Loading from '../../components/Loading';
import { getDepartment } from '../../features/department/departmentSlice';
const { Option } = Select;

const EmployeePage = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   //const [isSuccess, setIsSuccess] = useState(false);

   const [modalOpen, setModalOpen] = useState(false);
   const [editUser, setEditUser] = useState(null);

   const [loadigPage, setLoadingPage] = useState(true);

   const { user } = useSelector((state) => state.auth);
   const { employees, isLoading, isError, message, isSuccess } = useSelector(
      (state) => state.employee
   );

   const { departments } = useSelector((state) => state.department);

   let [department, setDepartment] = useState('');
   let department2 = [];
   if (departments) {
      department2 = departments.flat();
   }

   const handleChange = (e) => {
      const { name, value, files } = e.target;

      if (name === 'media') {
         if (files && files.length > 0) {
            setMedia(files[0]);
            return setMediaPreview(URL.createObjectURL(files[0]));
         }
      }
   };

   const [media, setMedia] = useState(null);
   const [mediaPreview, setMediaPreview] = useState(null);
   const [highlighted, setHighlighted] = useState(false);
   const inputRef = useRef();

   useEffect(() => {
      dispatch(getEmployees());
      dispatch(getDepartment());

      return () => {
         dispatch(reset());
      };
   }, [dispatch]);

   const handelChange = (e) => {
      let fileInput = false;
      if (e.target.files[0]) {
         fileInput = true;
      }
      if (fileInput) {
         try {
            Resizer.imageFileResizer(
               e.target.files[0],
               500,
               500,
               'JPEG',
               100,
               0,
               (uri) => {
             
                  setMedia(uri);
               },
               'base64',
               500,
               500
            );
         } catch (err) {
        
         }
      }
   };
   const onFinish = async (values, image) => {
      if (editUser === null) {
         try {
            let profilePicUrl;
            if (media !== null) {
               profilePicUrl = await uploadPic(media);
            }
            if (media !== null && !profilePicUrl) {
               setLoadingPage(false);
               toast.error('Error Uploading Image');
            }

            values.profilePicUrl = profilePicUrl;

       
            dispatch(createEmployee(values));
            window.location.reload();
         } catch (error) {
            toast.error(message);
         }
      } else {
         try {
            const eid = editUser._id;
            values.eid = eid;
            let profilePicUrl;
            if (media !== null) {
               profilePicUrl = await uploadPic(media);
            }
            if (media !== null && !profilePicUrl) {
               setLoadingPage(false);
               toast.error('Error Uploading Image');
            }
            values.profilePicUrl = profilePicUrl;

            dispatch(updateEmployee(values));
            toast.success('แก้ไขข้อมูลสำเร็จ', {
               position: 'top-right',
               autoClose: 3000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: 'light',
            });
            window.location.reload();
         } catch (error) {
            toast.error(message);
         }
      }
   };

   const deleteHanler = (record) => {
      if (window.confirm('Are you sure')) {
         const eid = record._id;
  

         dispatch(deleteEmployee(eid));
         toast.error('ลบข้อมูลสำเร็จ', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
         });
         window.location.reload();
      }
   };

   const columns = [
      {
         title: 'ชื่อ-สกุล',
         dataIndex: ['name', '_id'],
         key: 'name',
         width: '20%',
         render: (text, record) => (
            <p style={{ cursor: 'pointer' }}>{record['name']}</p>
         ),
      },

      {
         title: 'รูปภาพ',
         dataIndex: 'profilePicUrl',
         render: (profilePicUrl, record) => (
            <img
               src={profilePicUrl}
               alt=''
               style={{ objectFit: 'fill' }}
               height='100'
               width='100'
            />
         ),
      },
      {
         title: 'แผนก',
         dataIndex: 'department',
         key: 'department',
      },
      {
         title: 'ตำแหน่ง',
         dataIndex: 'position',
         key: 'position',
      },

      {
         title: 'cv',
         dataIndex: 'cv',
         key: 'cv',
         render: (record) => (
            <div>
               {' '}
               {record && record.length > 95
                  ? `${record.substring(0, 95)} ...`
                  : record}
            </div>
         ),
         width: '20%',
      },

      {
         title: 'อีเมล์',
         dataIndex: 'email',
         key: 'email',
      },

      {
         title: 'วันที่',
         dataIndex: 'createdAt',
         render: (createdAt) => (
            <div> {createdAt.toString().substring(0, 10)} </div>
         ),
      },
      {
         title: 'Action',
         key: 'action',
         render: (_, record) => (
            <Space size='middle'>
               {/* <p
                  style={{ cursor: 'pointer' }}
                  onClick={() => deleteHanler(record)}>
                  ลบ
               </p> */}
               <Tag
                  style={{ cursor: 'pointer' }}
                  onClick={() => deleteHanler(record)}
                  color='error'>
                  ลบ
               </Tag>
               {/* <p
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                     setEditUser(record);
                     setModalOpen(true);
                  }}>
                  แก้ไข
               </p> */}
               <Tag
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                     setEditUser(record);
                     setModalOpen(true);
                  }}
                  color='warning'>
                  {' '}
                  แก้ไข
               </Tag>
            </Space>
         ),
      },
   ];
   let employeeData = null;
   if (employees) {
      employeeData = employees.flat();
   }

   return (
      <>
         <DefaultLayout>
            {isLoading ? (
               <Loading />
            ) : (
               <>
                  <div className='d-flex justify-content-between'>
                     <h3> พนักงานทั้งหมด </h3>
                     <Button type='primary' onClick={() => setModalOpen(true)}>
                        {' '}
                        เพิ่มพนักงาน
                     </Button>
                  </div>
                  <Table columns={columns} dataSource={employeeData} />
               </>
            )}

            {modalOpen && (
               <Modal
                  title={`${
                     editUser !== null ? 'Edit Employee' : 'Add New Employee'
                  }`}
                  visible={modalOpen}
                  footer={false}
                  onCancel={() => {
                     setModalOpen(false);
                     setEditUser('');
                  }}>
                  <Form
                     layout='vertical'
                     initialValues={editUser}
                     onFinish={onFinish}>
                     <Form.Item
                        rules={[
                           {
                              required: true,
                              message: 'กรุณากรอกชื่อ-สกุล!',
                           },
                        ]}
                        name='name'
                        label='ชื่อ-สกุล'>
                        <Input />
                     </Form.Item>
                     <Form.Item
                        rules={[
                           {
                              required: true,
                              message: 'กรุณากรอกตำแหน่ง!',
                           },
                        ]}
                        name='position'
                        label='ตำแหน่ง'>
                        <Input />
                     </Form.Item>
                     <Form.Item
                        rules={[
                           {
                              required: true,
                              message: 'กรุณากรอกแผนก!',
                           },
                        ]}
                        label='แผนก'
                        name='department'>
                        <Select defaultValue=''>
                           {department2.map((e, index) => (
                              <Option de value={e.name} key={index}>
                                 {e.name}{' '}
                              </Option>
                           ))}
                        </Select>
                     </Form.Item>
                     <Form.Item
                        rules={[
                           {
                              required: true,
                              message: 'กรุณากรอกอีเมล์!',
                           },
                        ]}
                        name='email'
                        label='อีเมล์'>
                        <Input />
                     </Form.Item>

                     <Form.Item
                        rules={[
                           {
                              required: true,
                              message: 'กรุณากรอก CV!',
                           },
                        ]}
                        name='cv'
                        label='cv'>
                        <Input />
                     </Form.Item>

                     <input
                        name='imgUpload'
                        accept='image/*'
                        type='file'
                        onChange={handelChange}
                     />

                     {media && (
                        <ImagePreview>
                           <Image
                              style={{ borderRadius: '20px' }}
                              src={media}
                              width={150}
                              preview={false}
                           />
                        </ImagePreview>
                     )}
                     <br />
                     <div className='d-flex justify-content-end'>
                        <Button block htmlType='submit' type='primary'>
                           {' '}
                           SAVE
                        </Button>
                     </div>
                  </Form>
               </Modal>
            )}
         </DefaultLayout>
      </>
   );
};

const ImagePreview = styled.div`
   margin: 20px;
   padding: 15px 0 !important;
   text-align: center;
   border-radius: 5px;
   border: 1px solid;
`;

export default EmployeePage;
