import { Col, Pagination, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Article from '../components/Article';
import Footer from '../components/Footer';
import Header from '../components/Header';
import HeroCarousel from '../components/HeroCarousel';
import Loading from '../components/Loading';
import { getArticlesByCategory, reset } from '../features/article/articleSlice';
import styled from 'styled-components';

const PopculturePage = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const [loadigPage, setLoadingPage] = useState(true);

   const { articles, isLoading, isError, message } = useSelector(
      (state) => state.article
   );
   // pagination states
   const [number, setNumber] = useState(1);
   const postsPerPage = 6;

   useEffect(() => {
      if (loadigPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }

      if (isError) {
      }

      dispatch(getArticlesByCategory('popculture'));
      return () => {
         dispatch(reset());
      };
   }, []);

   let newData = articles.slice(
      (number - 1) * postsPerPage,
      postsPerPage * number
   );
   //   handle Pagination
   const handlePage = (pageNumber) => setNumber(pageNumber);

   return (
      <>
         <Header />
         {isLoading ? (
            <Loading />
         ) : (
            <>
               <HeroCarousel articles={articles} />
               <br />
               <br />
               <Container>
                  <div className='header-name mt-5 mb-5'>
                     <HeaderName> popculture </HeaderName>
                  </div>
                  <hr />
                  <br />
                  <div className='site-card-wrapper'>
                     <Row gutter={[20, 50]}>
                        {/* {article.map(
                        (i) =>
                         
                           (
                              <Col xs={24} md={12} xl={8}>
                                 <Article key={i._id} i={i} />
                              </Col>
                           )
                     )} */}

                        {newData.length ? (
                           newData.map((article) => {
                              return (
                                 <Col key={article._id} xs={24} md={12} xl={8}>
                                    <Article
                                       author={article.author.name}
                                       article={article}
                                    />
                                 </Col>
                              );
                           })
                        ) : (
                           <h1> ไม่พบบทความ</h1>
                        )}
                     </Row>
                     <div className='mt-4 mb-4'>
                        <Pagination
                           defaultCurrent={number}
                           pageSize={postsPerPage}
                           total={articles.length}
                           onChange={handlePage}
                        />
                     </div>
                  </div>
               </Container>
               <Footer />
            </>
         )}
      </>
   );
};

const HeaderName = styled.h1`
   margin: auto;
   border-radius: 5px;
   width: 25rem;
   text-align: center;
   padding: 15px;
   border: 5px solid #345c64;
`;

export default PopculturePage;
