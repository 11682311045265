import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userService from './userService';

const initialState = {
   users: [],
   user: {},
   isError: false,
   isSuccess: false,
   isLoading: false,
   message: '',
};

export const createUser = createAsyncThunk(
   'user/create',
   async ({ payload, callback }, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token;

         return await userService.createUser({ payload, callback }, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         console.log(message);
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const updateUser = createAsyncThunk(
   'user/update',
   async (authorData, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user;
         return await userService.updateUser(authorData, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getUsers = createAsyncThunk(
   'users/getUsers',
   async (_, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token;
         return await userService.getUsers(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getUserById = createAsyncThunk(
   'users/getUserById',
   async (authorId, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token;
         return await userService.getUserDetail(authorId, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const deleteUser = createAsyncThunk(
   'user/delete',
   async (id, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token;
         return await userService.deleteUser(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const userSlice = createSlice({
   name: 'user',
   initialState,
   reducers: {
      reset: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(createUser.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(createUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.user = action.payload;
         })
         .addCase(createUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(updateUser.pending, (state, action) => {
            state.isLoading = true;
         })
         .addCase(updateUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.user = action.payload;
         })
         .addCase(updateUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getUsers.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getUsers.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.users.push(action.payload);
         })
         .addCase(getUsers.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getUserById.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getUserById.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.user = action.payload;
         })
         .addCase(getUserById.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })

         .addCase(deleteUser.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(deleteUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.users = state.users.filter(
               (user) => user._id !== action.payload.id
            );
         })
         .addCase(deleteUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         });
   },
});

export const { reset } = userSlice.actions;
export default userSlice.reducer;
