import axios from 'axios';
import { toast } from 'react-toastify';

// const API_URL = 'http://localhost:8000/api/article/';
// const API_URL = '//field-feel-server.proen.app.ruk-com.cloud/api/article/';

let API_URL = '';

if (process.env.NODE_ENV === 'development') {
  API_URL = 'http://localhost:8000/api/article/';
} else {
  API_URL = '//api.field-feel.com/api/article/';
}
// GET ARTICLES
export const getArticles = async () => {
  // const config = {
  //    headers: {
  //       Authorization: `Bearer ${token}`,
  //    },
  // };

  const response = await axios.get(API_URL);

  return response.data;
};

// GET ARTICLES BY TAG
export const getArticleByTag = async (tag) => {
  const response = await axios.get(API_URL + `getArticleByTag/${tag}`);
  return response.data;
};

// GET ARTICLES BY AUTHOR
export const getArticlesByAuthor = async (authorId) => {
  const response = await axios.get(API_URL + `getbyauthor/${authorId}`);

  return response.data;
};

// GET ARTICLES BY PHOTOGRAPHER
export const getArticlesByAPhotoGrapher = async (id) => {
  const response = await axios.get(API_URL + `getByPhotoGrapher/${id}`);

  return response.data;
};

//GET ARTICLES BANNER
export const getArticlesBanner = async () => {
  const response = await axios.get(API_URL + `getbanner/`);

  return response.data;
};

// GET ARTICLES BY Category
export const getArticlesByCategory = async (category) => {
  const response = await axios.get(
    API_URL + `getbycategory/?category=${category}`
  );

  return response.data;
};

// GET ARTICLES BY Name
export const getArticlesName = async (title) => {
  const response = await axios.get(API_URL + `getByName/?title=${title}`);

  return response.data;
};
// GET ARTICLESDETAIL
export const getArticleById = async (id) => {
  const response = await axios.get(API_URL + `${id}`);

  return response.data;
};

// EDIT ARTICLE
export const editArticle = async (articleData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.put(
    API_URL + `${articleData.payload.aid}`,
    articleData,
    config
  );

  if (res.data.success) {
    articleData.callback(true);
    toast.success('แก้ไขบทความสำเร็จ', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    window.location.replace('/articles');
    return true;
  }
  articleData.callback(false, null);
  return false;
};

// CREATE ARTICLE
export const createArticle = async (articleData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const res = await axios.post(API_URL + 'createArticle', articleData, config);

  if (res.data.success) {
    articleData.callback(true);
    toast.success('อัพโหลดบทความสำเร็จ', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    //    window.location.replace('/articles');
    return true;
  }
  articleData.callback(false, null);
  return false;
};

// UPDATE ARTICLE DATAA
const editArticleData = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const res = await axios.put(
    API_URL + 'updateArticleData/' + data.aid,
    { data },
    config
  );

  return res.data;
};

// UPDATE ARTICLE BANNER
const updateBannerArticle = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const res = await axios.put(API_URL + 'updatebanner/' + id, {}, config);

  return res.data;
};

// Delete user article
const deleteArticle = async ({ payload, callback }, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.delete(API_URL + payload, config);

  console.log(res);

  if (res.status === 200) {
    callback(true);
    return;
  }
  callback(false);
  return;
};

const searchArticle = async (text) => {
  const response = await axios.get(API_URL + `searchArticle/${text}`);

  return response.data;
};

const removePhotographerArticle = async (ids, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.put(
    API_URL + 'removePhotoGrapherArticle',
    ids,
    config
  );
  return res.data;
};

const addPhotoGrapherToArticle = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const res = await axios.put(
    API_URL + 'addPhotoGrapherToArticle/' + data.article._id,
    data,
    config
  );
  return res.data;
};

const articleService = {
  editArticle,
  createArticle,
  getArticles,
  deleteArticle,
  getArticlesByAuthor,
  getArticlesByCategory,
  getArticleById,
  updateBannerArticle,
  getArticlesBanner,
  searchArticle,
  getArticlesName,
  removePhotographerArticle,
  editArticleData,
  addPhotoGrapherToArticle,
  getArticlesByAPhotoGrapher,
  getArticleByTag,
};

export default articleService;
