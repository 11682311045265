import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getArticleByTag } from '../features/article/articleSlice';
import { Col, Pagination, Row } from 'antd';
import Footer from '../components/Footer';
import Article from '../components/Article';
import Header from '../components/Header';
import Loading from '../components/Loading';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';

const ArticlesByTags = () => {
    const data = useParams();
    const navigate = useNavigate();
   const dispatch = useDispatch();

   const [loadigPage, setLoadingPage] = useState(true);

   const { articles, isLoading, isError, message } = useSelector(
      (state) => state.article
   );

   console.log(articles);

   // pagination states
   const [number, setNumber] = useState(1);
   const postsPerPage = 6;

   
   useEffect(() => {
    if (loadigPage) {
       setTimeout(() => {
          setLoadingPage(false);
       }, 1500);
    }

    dispatch(getArticleByTag(data.tag));
 }, [dispatch]);

    //   handle Pagination
    const handlePage = (pageNumber) => setNumber(pageNumber);
    
  return (
   <>
   <Header />
   {isLoading ? (
      <Loading />
   ) : (
      <>
         <br />
         <br />
         <Container>
            <div className='header-name mt-5 mb-5'>
               <HeaderName> TAG: {data.tag} </HeaderName>
            </div>
            <hr />
            <br />
            <div className='site-card-wrapper'>
               <Row gutter={[20, 50]}>
                  {/* {article.map(
                  (i) =>
                   
                     (
                        <Col xs={24} md={12} xl={8}>
                           <Article key={i._id} i={i} />
                        </Col>
                     )
               )} */}

                  {articles.length ? (
                     articles.map((article) => {
                        return (
                           <Col key={article._id} xs={24} md={12} xl={8}>
                              <Article
                                 author={article.author.name}
                                 article={article}
                              />
                           </Col>
                        );
                     })
                  ) : (
                     <h1> ไม่พบบทความ </h1>
                  )}
               </Row>
               <div className='mt-4 mb-4'>
                  <Pagination
                     defaultCurrent={number}
                     pageSize={postsPerPage}
                     total={articles.length}
                     onChange={handlePage}
                  />
               </div>
            </div>
         </Container>
         <Footer />
      </>
   )}
</>
  )
}

const HeaderName = styled.h1`
   margin: auto;
   border-radius: 5px;
   width: 25rem;
   text-align: center;
   padding: 15px;
   border: 5px solid #345c64;
`;


export default ArticlesByTags