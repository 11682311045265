import { Avatar, Button, Col, Form, Image, Input, List, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Loading from '../../components/Loading';

import { getArticlesByAuthor } from '../../features/article/articleSlice';
import { getAuthorById, updateAuthor } from '../../features/author/authorSlice';

import DefaultLayout from '../components/DefaultLayout ';
import Resizer from 'react-image-file-resizer';
import { uploadPic } from '../../utils/connect';
import { toast } from 'react-toastify';
import { getUserById, updateUser } from '../../features/users/userSlice';

const AdminProfile = () => {
   const dispatch = useDispatch();
   const location = useLocation();
   const [form] = Form.useForm();
   const [media, setMedia] = useState(null);
   const [mediaPreview, setMediaPreview] = useState(null);
   const [loadigPage, setLoadingPage] = useState(true);

   const {
      articles,
      isLoading: isArticleLoading,
      isError: isArticleError,
      message,
   } = useSelector((state) => state.article);
   const { user, isSuccess, isLoading, isError } = useSelector(
      (state) => state.user
   );

   const { state } = location;


   useEffect(() => {
      if (isError) {
       
      }

      dispatch(getUserById(state.record));
      form.setFieldsValue({
         name: user.username,
         email: user.email,
      });
   }, [state.record, user.username, form]);

   const onFinish = async (values) => {
      if (isSuccess) {
         toast.success('แก้ไขข้อมูลสำเร็จ', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
         });
      }

   
       dispatch(updateUser(values));
   };

   const handelChange = (e) => {
      setMediaPreview(URL.createObjectURL(e.target.files[0]));
    
      let fileInput = false;
      if (e.target.files[0]) {
         fileInput = true;
      }
      if (fileInput) {
         try {
            Resizer.imageFileResizer(
               e.target.files[0],
               500,
               500,
               'JPEG',
               100,
               0,
               (uri) => {
                
                  setMedia(uri);
               },
               'base64',
               500,
               500
            );
         } catch (err) {
         
         }
      }
   };

   if (isLoading || isArticleLoading) {
      return <Loading />;
   }

   return (
      <DefaultLayout>
         <Row gutter={24}>
            <Col md={18} lg={18} xs={24} sm={24}>
               <Form
                  form={form}
                  name='basic'
                  labelCol={{
                     span: 8,
                  }}
                  wrapperCol={{
                     span: 16,
                  }}
                  initialValues={{
                     name: user.username,
                     email: user.email,
                  }}
                  onFinish={onFinish}
                  autoComplete='off'>
                  <Form.Item
                     label='name'
                     name='name'
                     rules={[
                        {
                           required: true,
                           message: 'Please input your username!',
                        },
                     ]}>
                     <Input />
                  </Form.Item>

                  <Form.Item
                     label='email'
                     name='email'
                     rules={[
                        {
                           required: true,
                           message: 'Please input your email!',
                        },
                     ]}>
                     <Input />
                  </Form.Item>

                  <Form.Item
                     label='password'
                     name='password'
                     rules={[
                        {
                           required: true,
                           message: 'Please input your password!',
                        },
                     ]}>
                     <Input />
                  </Form.Item>

                  <Form.Item
                     wrapperCol={{
                        offset: 8,
                        span: 16,
                     }}>
                     <Button block type='primary' htmlType='submit'>
                        Submit
                     </Button>
                  </Form.Item>
               </Form>
            </Col>
         </Row>
      </DefaultLayout>
   );
};

export default AdminProfile;
