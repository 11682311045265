import axios from 'axios';

// const API_URL = 'http://localhost:8000/api/category/';
//const API_URL = "//field-feel-server.proen.app.ruk-com.cloud/api/category/";

let API_URL = '';

if (process.env.NODE_ENV === 'development') {
  API_URL = 'http://localhost:8000/api/category/';
} else {
  API_URL = '//api.field-feel.com/api/category/';
}

const getCategory = async () => {
  const response = await axios.get(API_URL + 'category');

  return response.data;
};

// Create new Category
const createCategory = async ({ payload, callback }, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.post(API_URL, payload, config);
  console.log(res);

  if (res.status === 200) {
    callback(true);
    return;
  }
  if (res.status === 401) {
    callback(false);
    return;
  }

  callback(false);
  return res.data;
};

// Delete author
const deleteCategory = async ({ payload, callback }, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.delete(API_URL + payload, config);

  console.log(res);

  if (res.status === 200) {
    callback(true);
    return true;
  }
  callback(false, null);
  return false;
};

// EDIT CATEGORY
const updateCategory = async (categoryData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token.token}`,
    },
  };
  console.log(categoryData);
  const response = await axios.put(
    API_URL + `${categoryData._id}`,
    categoryData,
    config
  );

  return response.data;
};

const categoryService = {
  getCategory,
  createCategory,
  deleteCategory,
  updateCategory,
};

export default categoryService;
