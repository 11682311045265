import {
   Avatar,
   Button,
   Col,
   Form,
   Image,
   Input,
   List,
   Modal,
   Row,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Article from '../components/Article';
import Footer from '../components/Footer';
import Header from '../components/Header';
import styled from 'styled-components';
import {
   getArticlesByAuthor,
   reset,
   searchArticles,
} from '../features/article/articleSlice';
import { getAuthorById } from '../features/author/authorSlice';

const AuthorScreen = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const location = useLocation();

   const { state } = location;

   const [loadigPage, setLoadingPage] = useState(true);

   const { articles, isLoading, isError, message } = useSelector(
      (state) => state.article
   );
   const { author } = useSelector((state) => state.author);

   useEffect(() => {
      if (loadigPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }

      dispatch(getAuthorById(state.authorId));
      dispatch(getArticlesByAuthor(state.authorId));
   }, []);

   const [isModalOpen, setModalOpen] = useState(false);
   const textSearch = useRef();
   const [loading, setLoading] = useState(false);
   const openModal = () => {
      setModalOpen(true);
   };

   const handleCancel = () => {
      setModalOpen(false);
   };

   const handleOk = () => {
      dispatch(searchArticles(textSearch.current));
      navigate(`/search/${textSearch.current}`);
      setLoading(true);
      setTimeout(() => {
         setLoading(false);
         setModalOpen(false);
      }, 3000);
   };

   return (
      <>
         <Header openModal={openModal} />

         <br />
         <Container>
            {/* <HeaderName>
               <h1> {state.authorName} </h1>
            </HeaderName> */}

            <Row className='mt-4 mb-4' gutter={10}>
               <Col span={8}>
                  {' '}
                  <Image
                     preview={false}
                     style={{
                        borderRadius: '10px',
                        marginRight: '40px !important',
                     }}
                     width={200}
                     src={author.picUrl}
                  />
               </Col>
               <Col span={8}>
                  {' '}
                  <div style={{ textAlign: 'left' }}>
                     <h2> {author.name} </h2>
                     <p> {author.bio} </p>
                  </div>
               </Col>
            </Row>

            <hr />

            <div className='site-card-wrapper'>
               <Row gutter={[20, 50]}>
                  {/* {article.map(
                        (i) =>
                         
                           (
                              <Col xs={24} md={12} xl={8}>
                                 <Article key={i._id} i={i} />
                              </Col>
                           )
                     )} */}

                  {articles.map((article) => {
                     return (
                        <Col key={article._id} xs={24} md={12} xl={8}>
                           <Article
                              article={article}
                              author={state.authorName}
                           />
                        </Col>
                     );
                  })}
               </Row>
            </div>
         </Container>
         <br />
         <br />
         <Footer />
         <Modal
            title='ค้นหา'
            visible={isModalOpen}
            footer={[
               <Button
                  key='submit'
                  type='primary'
                  loading={loading}
                  onClick={handleOk}>
                  ค้นหา
               </Button>,
            ]}
            onCancel={handleCancel}>
            <Form>
               <Form.Item label='ค้นหา' name='username'>
                  <Input
                     onChange={(e) => (textSearch.current = e.target.value)}
                  />
               </Form.Item>
            </Form>
         </Modal>
      </>
   );
};

const HeaderName = styled.div`
   padding: 20px;
   border: 10px solid black;
`;

export default AuthorScreen;
