import {
  Avatar,
  Button,
  Col,
  Form,
  Image,
  Input,
  List,
  Modal,
  Row,
  Tag,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HeroCarousel from "../components/HeroCarousel";
import Loading from "../components/Loading";
import {
  getArticleById,
  getArticlesByName,
  reset,
  searchArticles,
} from "../features/article/articleSlice";
import moment from "moment";
import { Parallax, Background } from "react-parallax";
import { Helmet } from "react-helmet";
import { FacebookIcon, TwitterIcon, FacebookShareButton } from "react-share";
import "moment/locale/th";
import styled from "styled-components";
import { FaClock } from "react-icons/fa";
import { TiMediaPlayOutline } from "react-icons/ti";
import { InlineReactionButtons } from "sharethis-reactjs";
import { InlineShareButtons } from "sharethis-reactjs";
import { StickyShareButtons } from "sharethis-reactjs";
import Meta from "../components/Meta";

moment.locale("th");

const ArticlePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { textName } = useParams();

  const params = useParams();
  const location = useLocation();
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state } = location;

  const { article, isLoading } = useSelector((state) => state.article);

  const { articles, viewCount } = article;

  const html = useRef(null);
  const textSearch = useRef();
  const [loadigPage, setLoadingPage] = useState(true);

  if (articles) {
    //  console.log(article.articles.photoGraphers);
    // console.log(articles.photoGraphers.map((e) => e.photoGrapher.name));
  }

  useEffect(() => {
    if (loadigPage) {
      setTimeout(() => {
        setLoadingPage(false);
      }, 4000);
    }

    dispatch(getArticlesByName(params.id));

    // if (isLoading === false) {
    //    console.log(isLoading);
    //    console.log(article);
    //    html.current.innerHTML = article.html_content;
    // }
  }, []);
  //dispatch, params.id, article, isLoading
  const isEmpty = (obj) => {
    return Object.getOwnPropertyNames(obj).length === 0;
  };
  if (html && html.current) {
    html.current.innerHTML = article.articles.html_content;
  }

  // if (isEmpty(article)) {
  //    console.log(isEmpty(article));
  // } else {
  //    console.log(123);
  //    console.log(article.html_content);
  //    //  html.current.innerHTML = article.html_content;
  //    console.log(html.current.innerHTML);
  // }

  const openModal = () => {
    setModalOpen(true);
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const handleOk = () => {
    dispatch(searchArticles(textSearch.current));
    navigate(`/search/${textSearch.current}`);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setModalOpen(false);
    }, 3000);
  };

  const insideStyles = {
    fontSize: "46px",
    color: "white",
    lineHeight: "3.5rem",
    fontWeight: "bold",
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  };

  return (
    <>
      <Header openModal={openModal} />
      {isLoading && <Loading />}

      {/* {article2 ? (
            <>
               <br />
               <br />
               <Parallax bgImage={article2.cover} strength={500}>
                  <div style={{ height: 500 }}>
                     <div style={insideStyles}> {article2?.title} </div>
                  </div>
               </Parallax>
               <div className='articlepage'>
                  <h1 className='blog_header'>{article2?.title}</h1>
                  <div className='blog_tag d-flex '>
                     <div className='topic'>
                        <Link
                           style={{ color: 'black ' }}
                           to={`/category/${article2.category}`}>
                           {' '}
                           {article2.category}{' '}
                        </Link>{' '}
                     </div>
                     <div
                        className='ml-5'
                        style={{ backgroundColor: 'transparent  !important' }}>
                        <FacebookShareButton
                           style={{
                              backgroundColor: 'transparent !important ',
                           }}
                           url={`window.location.href`}
                           //   url={`localhos:3000/article2/${params.id}`}
                           quote={'แชร์ไปที่เฟซบุ๊ค'}
                           description={'แชร์'}>
                           <FacebookIcon
                              style={{
                                 marginRight: '10px',
                                 backgroundColor: 'transparent !important',
                              }}
                              size={32}
                              round={true}
                           />
                        </FacebookShareButton>
                     </div>
                  </div>
                  <br />
                  <div className='blog_author'>
                     <div className='blog_author_avatar'>
                        <Avatar size={64} src={article2?.author?.picUrl} />
                     </div>
                     <div className='blog_author_info'>
                        <div className='blog_author_info_1'>
                           <div
                              className='blog_author_username'
                              onClick={() =>
                                 navigate(`/author/${article2?.author.name}`, {
                                    state: {
                                       authorName: article2.author.name,
                                       authorId: article2.author._id,
                                    },
                                 })
                              }>
                              {article2?.author?.name}
                           </div>
                        </div>
                        <div className='blog_author_info_2'>
                           <div className='blog_publish_date'>
                              {moment(article?.createdAt).format('LL')}
                           </div>
                           &nbsp;&nbsp;.&nbsp;&nbsp;
                           <div className='blog_readTime'>
                              {article?.readingTime}
                           </div>
                        </div>
                     </div>
                  </div>
                  <br />
                  <div className='html_content' ref={html}>
                     <div
                        style={{
                           width: '50vw',
                           height: '200px',
                           display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'center',
                        }}>
                        {' '}
                     </div>
                  </div>
               </div>
               <br />
               <br />
               <Footer />
               <Modal
                  title='ค้นหา'
                  visible={isModalOpen}
                  footer={[
                     <Button
                        key='submit'
                        type='primary'
                        loading={loading}
                        onClick={handleOk}>
                        ค้นหา
                     </Button>,
                  ]}
                  onCancel={handleCancel}>
                  <Form>
                     <Form.Item label='ค้นหา' name='username'>
                        <Input
                           onChange={(e) =>
                              (textSearch.current = e.target.value)
                           }
                        />
                     </Form.Item>
                  </Form>
               </Modal>
            </>
         ) : (
            <>
               <Loading />
            </>
         )} */}

      {isLoading ? (
        <Loading />
      ) : (
        <>
          {article.articles && (
            <>
              {/* <Meta
                        description={articles.description}
                        title={articles.enTitle}
                        image={articles.cover}
                     /> */}

              <br />
              <br />
              <Parallax bgImage={articles.cover}>
                {/* <Background>
                           <img src={articles.cover} />
                        </Background> */}
                <div style={{ height: 500 }}>
                  <div>
                    {" "}
                    <p className="parallax__title"> {articles?.title}</p>
                  </div>
                </div>
              </Parallax>
              <div style={{ backgroundColor: "rgb(249 249 249 / 63%) " }}>
                <div className="articlepage">
                  <h1 className="blog_header">{articles?.title}</h1>
                  <div className="blog_tag d-flex ">
                    <div className="topic">
                      <Link
                        style={{ color: "black " }}
                        to={`/category/${articles.category}`}
                      >
                        {" "}
                        {articles.category}{" "}
                      </Link>{" "}
                    </div>
                    <div
                      style={{
                        marginRight: "50px",
                      }}
                    >
                      {/* <FacebookShareButton
                                 style={{ width: '0' }}
                                 url={`field-feel.com/${params.id}`}
                                 description={'แชร์'}>
                                 <FacebookIcon
                                    style={{
                                       marginRight: '10px',
                                       backgroundColor:
                                          'transparent !important',
                                    }}
                                    size={32}
                                    round={true}
                                 />
                              </FacebookShareButton> */}
                    </div>

                    <div className="blog_author_info_2">
                      <div className="blog_publish_date">
                        <FaClock /> {moment(articles?.createdAt).format("LL")}
                      </div>
                      &nbsp;&nbsp;.&nbsp;&nbsp;
                      <div className="blog_readTime">
                        <TiMediaPlayOutline style={{ fontSize: "24px" }} />{" "}
                        {articles?.readingTime}
                      </div>
                    </div>
                    <div className="blog_read">
                      <TextRead
                        style={{
                          marginLeft: "15px",
                          fontSize: "14px",
                        }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            lineHeight: "1.5",
                            marginTop: "7px",
                            marginLeft: "1.25px",
                          }}
                        >
                          {viewCount}{" "}
                          <span
                            style={{
                              display: "block",
                              fontSize: "8px",
                            }}
                          >
                            READ
                          </span>
                        </p>
                      </TextRead>
                    </div>
                  </div>
                  <br />

                  <br />

                  <br />

                  <div
                    className="html_content"
                    style={{ backgroundColor: "#dbceb74d" }}
                    ref={html}
                  >
                    <div
                      style={{
                        width: "50vw",

                        height: "200px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                    </div>
                  </div>
                  <br />
                  <br />
                  {/* TAGS SECTION */}
                  <div>
                    {/* <List
                      split={false}
                      grid={{
                        gutter: 16,
                        xs: 4,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                        xxl: 6,
                      }}
                      dataSource={articles.tags}
                      renderItem={(item) => (
                        // <List.Item>
                        //   <Tag color="#f50" closable>
                        //     {item}
                        //   </Tag>
                        // </List.Item>
                      <Link
                      to={`/tags/${item}`}
                      >
                       <p className="tags-article"
                       
                       > {item}</p>
                      </Link>
                      )}
                    /> */}
                    <div className="tags">
                      <div className="d-flex flex-wrap">
                        {articles.tags.map((e) => {
                          return (
                            <Link
                              to={`/tags/${e}`}
                              className="tags-article "
                            >
                              {e}
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />

                  {/*  AUTHOR SECTION  */}
                  <h4 style={{ textAlign: "left" }}> Author </h4>
                  <div className="blog_author">
                    <div className="blog_author_avatar">
                      <Avatar size={128} src={articles?.author?.picUrl} />
                    </div>
                    <div className="blog_author_info">
                      <div className="blog_author_info_1">
                        <div
                          className="blog_author_username"
                          onClick={() =>
                            navigate(`/author/${articles?.author.name}`, {
                              state: {
                                authorName: articles.author.name,
                                authorId: articles.author._id,
                              },
                            })
                          }
                        >
                          {articles?.author?.name}
                        </div>
                      </div>
                      <div className="blog_author_info_2">
                        <div className="blog_readTime">
                          {articles.author?.bio}
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />
                  <br />
                  {articles.photoGraphers.length >= 1 ? (
                    <>
                      <h4 style={{ textAlign: "left" }}> PhotoGrapher </h4>
                      {articles.photoGraphers.map((e) => {
                        return (
                          <div className="blog_author">
                            <div className="blog_author_avatar">
                              <Avatar
                                size={128}
                                src={e.photoGrapher.picUrl.url}
                              />
                            </div>
                            <div className="blog_author_info">
                              <div className="blog_author_info_1">
                                <div
                                  className="blog_author_username"
                                  onClick={() =>
                                    navigate(
                                      `/photographer/${e.photoGrapher.name}`,
                                      {
                                        state: {
                                          name: e.photoGrapher.name,
                                          id: e.photoGrapher._id,
                                        },
                                      }
                                    )
                                  }
                                >
                                  {e.photoGrapher.name}
                                </div>
                              </div>
                              <div className="blog_author_info_2">
                                <div className="blog_readTime">
                                  {e.photoGrapher.bio}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : null}
                </div>
              </div>
              <br />
              <br />
              <Footer />
            </>
          )}
        </>
      )}
    </>
  );
};

const TextRead = styled.div`
  display: inline-block;
  width: 65px;
  // height: 65px;
  padding-left: 7px;
  font-size: 15px;
  color: #fff;
  text-align: center !important;
  border-radius: 100px;
  line-height: 1.14 !important;
  background-color: #345c64;
`;

export default ArticlePage;
