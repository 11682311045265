import axios from 'axios';

// const API_URL = '/api/employee/';
//const API_URL = "//field-feel-server.proen.app.ruk-com.cloud/api/employee/";

let API_URL = '';

if (process.env.NODE_ENV === 'development') {
  API_URL = 'http://localhost:8000/api/employee/';
} else {
  API_URL = '//api.field-feel.com/api/employee/';
}

// Create new employee
const createEmployee = async (employeeData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.post(API_URL, employeeData, config);
  console.log(res);
  return res.data;
};

// Get Employees
const getEmployees = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL, config);

  return response.data;
};

const updateEmployee = async (employeeData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token.token}`,
    },
  };

  const response = await axios.put(
    API_URL + `/${employeeData.eid}`,
    employeeData,
    config
  );

  return response.data;
};

const deleteEmployee = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  console.log(id);
  const res = await axios.delete(API_URL + id, config);

  return res.data;
};

const authorService = {
  createEmployee,
  getEmployees,
  updateEmployee,
  deleteEmployee,
};

export default authorService;
