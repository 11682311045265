import axios from 'axios';

// const API_URL = "http://localhost:8000/api/users/";
//const API_URL = "//field-feel-server.proen.app.ruk-com.cloud/api/users/";

let API_URL = '';

if (process.env.NODE_ENV === 'development') {
  API_URL = 'http://localhost:8000/api/users/';
} else {
  API_URL = '//api.field-feel.com/api/users/';
}

// Register user
const register = async (userData) => {
  const response = await axios.post(API_URL, userData);

  if (response.data) {
    localStorage.setItem('userInfo', JSON.stringify(response.data));
  }

  return response.data;
};

// Login user
const login = async (userData) => {
  const response = await axios.post(API_URL + 'login', userData);

  if (response.data) {
    localStorage.setItem('userInfo', JSON.stringify(response.data));
  }

  return response.data;
};

// Logout user
const logout = () => {
  localStorage.removeItem('userInfo');
};

const authService = {
  register,
  logout,
  login,
};

export default authService;
