import React, { useEffect, useState } from 'react';
import { Image, Layout, Menu } from 'antd';
import '../css/layout.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BiHomeAlt, BiCategory } from 'react-icons/bi';
import { CgUserList } from 'react-icons/cg';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';
import { RiArticleFill } from 'react-icons/ri';

import logo2 from '../../assets/logo2.jpg';
import { logout } from '../../features/auth/authSlice';
import { BsFillPeopleFill } from 'react-icons/bs';
import { IoMdCamera } from 'react-icons/io';
import {
   MdAdminPanelSettings,
   MdFeaturedPlayList,
   MdLogout,
} from 'react-icons/md';
import axios from 'axios';

const { Header, Sider, Content } = Layout;

const DefaultLayout = (props) => {
   const location = useLocation();
   const navigate = useNavigate();
   const [collapsed, setCollapsed] = useState(false);
   const { user } = useSelector((state) => state.auth);


   const {token} = user

   const dispatch = useDispatch();

   useEffect(() => {
      const tokenExpired = async () => {
         const res = await axios.post(
         '//field-feel-server.proen.app.ruk-com.cloud/api/users/tokenExpired',
         //  '//localhost:8000/api/users/tokenExpired',
            { token },
            {
               headers: {
                  Authorization: `Bearer ${token}`,
               },
            }
         );
     
         if (res.data.message === 'token expired') {
            dispatch(logout());
            navigate('/login');
         }
      };
      tokenExpired();

   }, [])
   

   const toggle = () => {
      setCollapsed(!collapsed);
   };

   const logoutHandler = () => {
      dispatch(logout());
      navigate('/login');
   };

   return (
      <Layout>
         <Sider trigger={null} collapsible collapsed={collapsed}>
            <div className='logo mb-5 mt-3'>
               {/* <h3>{collapsed ? 'FEILD' : 'FF'}</h3> */}
               <Image src={logo2} width={50} preview={false} />
            </div>
            <Menu
               theme='light'
               mode='inline'
               defaultSelectedKeys={window.location.pathname}>
               <Menu.Item key='/dashboard' icon={<BiHomeAlt />}>
                  <Link to='/dashboard'>หน้าหลัก</Link>
               </Menu.Item>
               <Menu.Item key='/admin/admin' icon={<MdAdminPanelSettings />}>
                  <Link to='/admin/admin'>แอดมิน</Link>
               </Menu.Item>
               <Menu.Item key='/admin/author' icon={<BsFillPeopleFill />}>
                  <Link to='/admin/author'>นักเขียน</Link>
               </Menu.Item>
               <Menu.Item key='/admin/photoGrapher' icon={<IoMdCamera />}>
                  <Link to='/admin/photoGrapher'>ช่างภาพ</Link>
               </Menu.Item>
               <Menu.Item key='/articles' icon={<RiArticleFill />}>
                  <Link to='/articles'>บทความ</Link>
               </Menu.Item>
               <Menu.Item key='/admin/category' icon={<BiCategory />}>
                  <Link to='/admin/category'>หมวดหมู่บทความ</Link>
               </Menu.Item>
               <Menu.Item key='/admin/employee' icon={<CgUserList />}>
                  <Link to='/admin/employee'>พนักงาน</Link>
               </Menu.Item>
               <Menu.Item key='/admin/department' icon={<MdFeaturedPlayList />}>
                  <Link to='/admin/department'>แผนก</Link>
               </Menu.Item>

               {/* {cName &&
                  cName.map((e) => {
                     return (
                        <Menu.Item key={`/admin/${e}`}>
                           <Link to={`/admin/${e}`}> {e} </Link>
                        </Menu.Item>
                     );
                  })} */}

               <Menu.Item
                  key='/logout'
                  onClick={logoutHandler}
                  icon={<MdLogout />}>
                  ออกจากระบบ
               </Menu.Item>
            </Menu>
         </Sider>
         <Layout className='site-layout'>
            <Header className='site-layout-background' style={{ padding: 10 }}>
               {React.createElement(
                  collapsed ? AiOutlineMenuFold : AiOutlineMenuUnfold,
                  {
                     className: 'trigger',
                     onClick: toggle,
                  }
               )}

               <h4 className='mx-5 my-1'> </h4>
            </Header>
            <div className='d-flex'>
               {' '}
               <Content
                  className='site-layout-background'
                  style={{
                     margin: '10px',
                     padding: 24,
                     minHeight: '80vh',
                  }}>
                  {props.children}
               </Content>
               {location.pathname === '/' && (
                  <Content className='site-layout-background-right'></Content>
               )}
            </div>
         </Layout>
      </Layout>
   );
};

export default DefaultLayout;
