import { Col, Row, Pagination, Modal, Button, Form, Input } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Article from '../components/Article';
import Footer from '../components/Footer';
import Header from '../components/Header';
import HeroCarousel from '../components/HeroCarousel';
import Loading from '../components/Loading';
import {
   getArticlesByCategory,
   reset,
   searchArticles,
} from '../features/article/articleSlice';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const CategoryScreen = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const location = useLocation();
   const params = useParams();


   const [totalPage, setTotalPage] = useState(0);
   const [current, setCurrent] = useState(1);
   const [minIndex, setMinIndex] = useState(0);
   const [maxIndex, setMaxIndex] = useState(0);

   // pagination states
   const [number, setNumber] = useState(1);

   const [loadigPage, setLoadingPage] = useState(true);

   const { articles, isLoading, isError, message } = useSelector(
      (state) => state.article
   );

   const postsPerPage = 6;

   useEffect(() => {
      if (loadigPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }

      dispatch(getArticlesByCategory(params.c));
      // setTotalPage(articles.length / pageSize);
      // setMaxIndex(pageSize);
      return () => {
         dispatch(reset());
      };
   }, [dispatch, params.c]);
   let newData = articles.slice(
      (number - 1) * postsPerPage,
      postsPerPage * number
   );
   //   handle Pagination
   const handlePage = (pageNumber) => setNumber(pageNumber);
   const [isModalOpen, setModalOpen] = useState(false);
   const textSearch = useRef();
   const [loading, setLoading] = useState(false);
   const openModal = () => {
      setModalOpen(true);
   };

   const handleCancel = () => {
      setModalOpen(false);
   };

   const handleOk = () => {
      dispatch(searchArticles(textSearch.current));
      navigate(`/search/${textSearch.current}`);
      setLoading(true);
      setTimeout(() => {
         setLoading(false);
         setModalOpen(false);
      }, 3000);
   };
   return (
      <>
         {/* <Helmet>
            <title>{params.c}| Field-Feel </title>
            <meta charset='utf-8' />
            <meta http-equiv='X-UA-Compatible' content='IE=edge' />
            <meta name='csrf_token' content='' />
            <meta property='type' content='website' />
            <meta property='url' content={location.pathname} />
            <meta
               name='viewport'
               content='width=device-width, initial-scale=1, shrink-to-fit=no'
            />
            <meta name='msapplication-TileColor' content='#ffffff' />
            <meta
               name='msapplication-TileImage'
               content='/ms-icon-144x144.png'
            />
            <meta name='theme-color' content='#ffffff' />
            <meta name='_token' content='' />
            <meta name='robots' content='noodp' />
            <meta property='title' content={params.c} />
            <meta property='quote' content={params.c} />
            <meta name='description' content={params.c} />
            <meta property='og:locale' content='en_US' />
            <meta property='og:type' content='website' />
            <meta property='og:title' content={params.c} />
            <meta property='og:quote' content={params.c} />
            <meta property='og:hashtag' content={params.c} />
            <meta property='og:url' content={location.pathname} />
            <meta property='og:site_name' content='CampersTribe' />
            <meta property='og:description' content={params.c} />{' '}
         </Helmet> */}
         <Header openModal={openModal} />
         {isLoading ? (
            <Loading />
         ) : (
            <>
               <HeroCarousel articles={articles} />
               <br />
               <br />
               <Container>
                  <div className='header-name mt-5 mb-5'>
                     <HeaderName> {params.c} </HeaderName>
                  </div>
                  <hr />
                  <br />
                  <div className='site-card-wrapper'>
                     <Row gutter={[20, 50]}>
                        {/* {article.map(
                        (i) =>
                         
                           (
                              <Col xs={24} md={12} xl={8}>
                                 <Article key={i._id} i={i} />
                              </Col>
                           )
                     )} */}

                        {/* {articles.map((article) => {
                           return (
                              <Col key={article._id} xs={24} md={12} xl={8}>
                                 <Article
                                    author={article.author.name}
                                    article={article}
                                 />
                              </Col>
                           );
                        })} */}

                        {newData.length ? (
                           newData.map((article) => {
                              return (
                                 <Col key={article._id} xs={24} md={12} xl={8}>
                                    <Article
                                       author={article.author.name}
                                       article={article}
                                    />
                                 </Col>
                              );
                           })
                        ) : (
                           <h1> ไม่พบบทความ</h1>
                        )}
                     </Row>
                     <div className='mt-4 mb-4'>
                        <Pagination
                           defaultCurrent={number}
                           pageSize={postsPerPage}
                           total={articles.length}
                           onChange={handlePage}
                        />
                     </div>
                  </div>
               </Container>
               <Footer />
               <Modal
                  title='ค้นหา'
                  visible={isModalOpen}
                  footer={[
                     <Button
                        key='submit'
                        type='primary'
                        loading={loading}
                        onClick={handleOk}>
                        ค้นหา
                     </Button>,
                  ]}
                  onCancel={handleCancel}>
                  <Form>
                     <Form.Item label='ค้นหา' name='username'>
                        <Input
                           onChange={(e) =>
                              (textSearch.current = e.target.value)
                           }
                        />
                     </Form.Item>
                  </Form>
               </Modal>
            </>
         )}
      </>
   );
};

const HeaderName = styled.h1`
   margin: auto;
   border-radius: 5px;
   width: 25rem;
   text-align: center;
   padding: 15px;
   border: 5px solid #345c64;
`;

export default CategoryScreen;
