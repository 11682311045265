import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import categoryService from './categoryService';

const initialState = {
   categories: [],
   category: {},
   isError: false,
   isSuccess: false,
   isLoading: false,
   message: '',
};

// Create new author
export const createCategory = createAsyncThunk(
   'category/create',
   async ({ payload, callback }, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token;

         return await categoryService.createCategory(
            { payload, callback },
            token
         );
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         console.log(message);
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// Get authors
export const getCategory = createAsyncThunk(
   'category/getCategory',
   async (_, thunkAPI) => {
      try {
         return await categoryService.getCategory();
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// Delete author
export const deleteCategory = createAsyncThunk(
   'category/delete',
   async (id, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token;
         return await categoryService.deleteCategory(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// Update Category
export const updateCategory = createAsyncThunk(
   'category/update',
   async (categoryData, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user;
         return await categoryService.updateCategory(categoryData, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const categoSlice = createSlice({
   name: 'category',
   initialState,
   reducers: {
      reset: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(getCategory.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getCategory.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.categories = null
               ? state.categories.push(action.payload)
               : (state.categories = action.payload);
            // if (state.categories === null) {
            //    state.categories.push(action.payload);
            // }
         })
         .addCase(getCategory.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(createCategory.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(createCategory.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.categories = action.payload;
         })
         .addCase(createCategory.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(deleteCategory.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(deleteCategory.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.categories = state.categories.filter(
               (category) => category._id !== action.payload.id
            );
         })
         .addCase(deleteCategory.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(updateCategory.pending, (state, action) => {
            state.isLoading = true;
         })
         .addCase(updateCategory.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.category = action.payload;
         })
         .addCase(updateCategory.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         });
   },
});

export const { reset } = categoSlice.actions;
export default categoSlice.reducer;
