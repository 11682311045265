import { Button, Card, Col, Form, Input, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { getAboutUs } from '../../features/aboutUs/aboutUsSlice';

import DefaultLayout from '../components/DefaultLayout ';

const Home = () => {
   const [modalOpen, setModalOpen] = useState(false);
   const [data, setData] = useState(null);
   const dispatch = useDispatch();

   const { aboutUs } = useSelector((state) => state.aboutUs);


   useEffect(() => {
      dispatch(getAboutUs());
   }, []);

   const onFinish = () => {};
   return (
      <DefaultLayout>
         <Row gutter={[26, 26]}>
            <Col span={8}>
               <Link to='/admin/category'>
                  <Card
                     title='จัดการหมวดหมู่'
                     hoverable
                     bordered={false}></Card>
               </Link>
            </Col>
            <Col span={8}>
               <Link to='/admin/employee'>
                  <Card title='จัดการพนักงาน' hoverable bordered={false}></Card>
               </Link>
            </Col>
            <Col span={8}>
               <Link to='/admin/department'>
                  <Card title='จัดการแผนก' hoverable bordered={false}></Card>
               </Link>
            </Col>
            <Col span={8}>
               <Link to='/admin/about'>
                  <Card
                     // onClick={() => {
                     //    setData(aboutUs);
                     //    setModalOpen(true);
                     // }}
                     title='จัดการเกี่ยวกับเรา'
                     hoverable
                     bordered={false}></Card>
               </Link>
            </Col>
         </Row>
         {modalOpen && (
            <Modal
               title=''
               visible={modalOpen}
               footer={false}
               onCancel={() => {
                  setModalOpen(false);
               }}>
               <Form layout='vertical' initialValues={data} onFinish={onFinish}>
                  <Form.Item name='title' label='หัวข้อ'>
                     <Input />
                  </Form.Item>

                  <Form.Item name='description' label='คำอธิบาย'>
                     <Input />
                  </Form.Item>

                  <div className='d-flex justify-content-end'>
                     <Button htmlType='submit' type='primary'>
                        {' '}
                        SAVE
                     </Button>
                  </div>
               </Form>
            </Modal>
         )}
      </DefaultLayout>
   );
};

export default Home;
