import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import DefaultLayout from '../components/DefaultLayout ';
import Loading from '../../components/Loading';
import { AiOutlineInbox } from 'react-icons/ai';

import { uploadImage, uploadPic } from '../../utils/connect';
import { Button, Form, Image, Input, Modal, Space, Table, Upload } from 'antd';
import ImageDropDiv from '../components/ImageDropDiv';
import { toast } from 'react-toastify';
import Resizer from 'react-image-file-resizer';
import styled from 'styled-components';
import {
   createPhotoGrahper,
   deletePhotoGrapher,
   getPhotoGrapher,
   reset,
} from '../../features/photoGrapher/photoGrapherSlice';

const PhotoGrapherPage = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const [isSuccess, setIsSuccess] = useState(false);

 
   const [modalOpen, setModalOpen] = useState(false);
   const [editUser, setEditUser] = useState(null);

   const [loadigPage, setLoadingPage] = useState(true);

   const { user } = useSelector((state) => state.auth);

   const { photoGraphers } = useSelector((state) => state.photoGrapher);
   let photoGrahperData = null;
   if (photoGraphers) {
      photoGrahperData = photoGraphers.flat();
   }

   const [media, setMedia] = useState(null);
   const [mediaPreview, setMediaPreview] = useState(null);
   const [highlighted, setHighlighted] = useState(false);
   const inputRef = useRef();

   useEffect(() => {
      if (loadigPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }
      if (!user) {
         navigate('/login');
      }

      dispatch(getPhotoGrapher());
      return () => {
         dispatch(reset());
      };
   }, [dispatch, isSuccess]);

   const columns = [
      {
         title: 'ชื่อนักเขียน',
         dataIndex: ['name', '_id'],
         key: 'name',
         width: '20%',
         render: (text, record) => (
            <p
               style={{ cursor: 'pointer' }}
               onClick={() =>
                  navigate('/admin/photoGrapherDetail', {
                     state: {
                        record: record['_id'],
                     },
                  })
               }>
               {record['name']}
            </p>
         ),
      },

      {
         title: 'Image',
         dataIndex: 'picUrl',
         render: (picUrl, record) => (
            <img
               src={picUrl.url}
               alt=''
               style={{ objectFit: 'fill' }}
               height='100'
               width='100'
            />
         ),
      },

      {
         title: 'bio',
         dataIndex: 'bio',
         key: 'bio',
         render: (record) => (
            <div>
               {' '}
               {record && record.length > 95
                  ? `${record.substring(0, 95)} ...`
                  : record}
            </div>
         ),
         width: '20%',
      },
      {
         title: 'บทความ',
         dataIndex: 'articles',
         key: 'articles',
         render: (record) => <h5>ดูบทความทั้งหมด </h5>,
      },
      {
         title: 'อีเมล์',
         dataIndex: 'email',
         key: 'email',
      },
      {
         title: 'เบอร์โทร',
         dataIndex: 'tel',
         key: 'tel',
      },

      {
         title: 'วันที่',
         dataIndex: 'createdAt',
         render: (createdAt) => (
            <div> {createdAt.toString().substring(0, 10)} </div>
         ),
      },
      {
         title: 'Action',
         key: 'action',
         render: (_, record) => (
            <Space size='middle'>
               <p
                  style={{ cursor: 'pointer' }}
                  onClick={() => deleteHanler(record)}>
                  Delete
               </p>
            </Space>
         ),
      },
   ];

   const handleImageUploadBefore = (files, info, uploadHandler) => {
      // uploadHandler is a function
      uploadImage(files[0])
         .then((data) =>
            uploadHandler({
               result: [
                  { url: data, size: files[0].size, name: files[0].name },
               ],
            })
         )
         .catch((err) => {
     
            uploadHandler('Image could not be uploaded');
         });
      //console.log(files, info,uploadHandler)
   };

   const handleImageUpload = (e) => {

      // let fileInput = false;
      // if (e.target.files[0]) {
      //    fileInput = true;
      // }
      // if (fileInput) {
      //    try {
      //       Resizer.imageFileResizer(
      //          e.target.files[0],
      //          500,
      //          500,
      //          'JPEG',
      //          100,
      //          0,
      //          (uri) => {

      //             setMedia(uri);
            
      //             //  setMedia(uri);
      //          },
      //          'base64',
      //          500,
      //          500
      //       );
      //    } catch (err) {
         
      //    }
      // }

      const file = e.target.files[0];

      TransformFileData(file);
   };

   const TransformFileData = (file) => {
      const reader = new FileReader();

      if (file) {
         reader.readAsDataURL(file);
         reader.onloadend = () => {
            setMedia(reader.result);
         };
      } else {
         setMedia('');
      }
   };

   const onFinish = async (values) => {
      if (media) {
         values.profilePicUrl = media;
         dispatch(createPhotoGrahper(values));
      } else {
         toast.warning('กรุณาเลือกรูปภาพ');
      }

      const callback = (res) => {
         if (res) {
            setIsSuccess(true);
            setModalOpen(false);
            toast.success('สำเร็จ', {
               position: 'top-right',
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: 'light',
            });
         } else {
       
            toast.error('ไม่สำเร็จ');
         }
      };
      dispatch(createPhotoGrahper({ payload: values, callback: callback }));
   };

   const deleteHanler = (record) => {
      if (window.confirm('ยืนยันการลบข้อมูลช่างภาพ')) {
         const callback = (res) => {
            if (res) {
               setIsSuccess(true);
               toast.success('ลบข้อมูลช่างภาพ', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
               });
            } else {
               toast.error('ไม่สำเร็จ');
            }
         };

         const aid = record._id;

            dispatch(deletePhotoGrapher({ payload: aid, callback: callback }));
      }
   };

   return (
      <DefaultLayout>
         {loadigPage && <Loading />}
         <div className='d-flex justify-content-between'>
            <h3> รายการช่างภาพทั้งหมด </h3>
            <Button type='primary' onClick={() => setModalOpen(true)}>
               {' '}
               เพิ่มช่างภาพ
            </Button>
         </div>
         <Table columns={columns} dataSource={photoGrahperData} />;
         {modalOpen && (
            <Modal
               title='เพิ่มนักเขียนใหม่'
               visible={modalOpen}
               footer={false}
               onCancel={() => {
                  setModalOpen(false);
                  setEditUser('');
               }}>
               <Form
                  layout='vertical'
                  initialValues={editUser}
                  onFinish={onFinish}>
                  <Form.Item name='name' label='ชื่อ-สกุล'>
                     <Input />
                  </Form.Item>

                  <Form.Item name='email' label='อีเมล์'>
                     <Input />
                  </Form.Item>

                  <Form.Item name='tel' label='เบอร์โทร'>
                     <Input />
                  </Form.Item>

                  <Form.Item name='bio' label='bio'>
                     <Input />
                  </Form.Item>

                  <input
                     name='imgUpload'
                     accept='image/*'
                     type='file'
                     onChange={handleImageUpload}
                  />

                  {media && (
                     <ImagePreview>
                        <Image
                           style={{ borderRadius: '20px' }}
                           src={media}
                           width={150}
                           preview={false}
                        />
                     </ImagePreview>
                  )}

                  <br />
                  <div className='d-flex justify-content-end'>
                     <Button block htmlType='submit' type='primary'>
                        {' '}
                        SAVE
                     </Button>
                  </div>
               </Form>
            </Modal>
         )}
      </DefaultLayout>
   );
};

const ImagePreview = styled.div`
   margin: 20px;
   padding: 15px 0 !important;
   text-align: center;
   border-radius: 5px;
   border: 1px solid;
`;

export default PhotoGrapherPage;
