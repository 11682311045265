import axios from "axios";
const API_URL = "/api/aboutus/";
//const API_URL = "//field-feel-server.proen.app.ruk-com.cloud/api/aboutus/";

const getAboutUs = async () => {
  const response = await axios.get(API_URL);

  return response.data;
};

// Create new Category
const createAboutUs = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.post(API_URL, data, config);

  return res.data;
};

// Create new Category
const updateAboutUs = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  console.log(data);

  const res = await axios.put(API_URL + "update/" + data._id, data, config);

  return res.data;
};

const deleteAbout = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.delete(API_URL + "delete/" + id, config);

  return res.data;
};

const aboutUsService = {
  createAboutUs,
  getAboutUs,
  updateAboutUs,
  deleteAbout,
};

export default aboutUsService;
