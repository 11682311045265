import React, { useEffect } from "react";

import "../resource/Footer.css";
import {
  BsFacebook,
  BsTwitter,
  BsYoutube,
  BsInstagram,
  BsTelephoneFill,
} from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { Container } from "react-bootstrap";
import { Col, Image, Row } from "antd";
import { Link } from "react-router-dom";
import { FaClock } from "react-icons/fa";
import logo from "../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../features/category/categorySlice";

const Footer = () => {
  const dispatch = useDispatch();
  const { categories, isLoading, isError, message } = useSelector(
    (state) => state.category
  );

  let categories2 = categories.flat();
  let cName = categories2.map((e) => e.name);

  useEffect(() => {
    dispatch(getCategory());
  }, []);
  const handleClickNav = (e) => {
    localStorage.setItem("nav", e);
  };

  return (
    <>
      {/* <footer className='footer section'>
            <div className='footer__container container grid'>
               <div className='footter__content'>
                  <h3 className='footer__title'>Type </h3>

                  <ul className='footer__links'>
                     <li>
                        <a href='#' className='footer__link'>
                           book
                        </a>
                     </li>
                     <li>
                        <a href='#' className='footer__link'>
                           local
                        </a>
                     </li>
                     <li>
                        <a href='#' className='footer__link'>
                           gender
                        </a>
                     </li>
                     <li>
                        <a href='#' className='footer__link'>
                           Popculture
                        </a>
                     </li>

                     <li>
                        <a href='#' className='footer__link'>
                           lifestyle
                        </a>
                     </li>
                     <li>
                        <a href='#' className='footer__link'>
                           Social Movement
                        </a>
                     </li>
                  </ul>
               </div>

               <div className='footter__content'>
                  <h3 className='footer__title'>about us</h3>
               </div>

               <div className='footter__content'>
                  <h3 className='footer__title'>contact us</h3>
               </div>

               <div className='footter__content'>
                  <h3 className='footer__title'>follow us</h3>

                  <ul className='footer__social'>
                     <a href='' className='footer__social-link'>
                        <BsFacebook className='mr-2' />
                     </a>

                     <a href='' className='footer__social-link'>
                        <BsTwitter className='mr-2' />
                     </a>

                     <a href='' className='footer__social-link'>
                        <BsInstagram className='mr-2' />
                     </a>

                     <a href='' className='footer__social-link'>
                        <BsYoutube className='mr-2' />
                     </a>
                  </ul>
               </div>
            </div>

            <span className='footer__copy'>
               {' '}
               &#169; FIELD. All rights reserved{' '}
            </span>
         </footer> */}

      <footer id="footer">
        <Container>
          <Row gutter={[26, 36]}>
            <Col sm={24} xs={24} md={24} lg={12} xl={12}>
              {" "}
              <div>
                <Image
                  src={logo}
                  style={{
                    backgroundColor: "#fff",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                  width={75}
                  preview={false}
                />
                <br />
                <h1 className="footer-title  mt-3 ">About Us</h1>

                <div className="secondary-title text-light ">
                  <p className="mt-2 ">
                    ฟิล์ด - ฟิล มีเดีย (Field - Feel Co., Ltd.)
                    ริเริ่มโดยกลุ่มนักเรียนสื่อ และนักเรียนมานุษยวิทยา
                    ต้องการมีส่วนร่วมสื่อสารภายใต้แนวคิด "สื่อสาร สัมผัสสนาม
                    ข้ามพรมแดน" เพื่อบอกเล่าและขับเคลื่อนประเด็นทางสังคม
                    การเมือง และวัฒนธรรม ในระดับมหภาค ผ่านมุมมองจุลภาค
                    ในแต่ละท้องถิ่น ตีแผ่ความรุนแรงเชิงโครงสร้าง
                    ผ่านเรื่องราวสายตาของคนตัวเล็ก ๆ ฟิล์ด ฟิล มีเดีย
                    มีเป้าหมายเพื่อพาผู้คนในสังคมก้าวข้าม
                    ผ่านพรมแดนแห่งความรุนแรงที่แบ่งแยกเราไว้
                    ทั้งพรมแดนทางความคิด พรมเเดนทางเพศ พรมแดนทางวัฒนธรรม
                    พรมแดนรัฐชาติ ฯลฯ
                    จนเกิดเป็นสังคมที่ผนวกรวมทุกคนเข้าไว้ด้วยกัน
                    บนพื้นฐานของความเข้าอกเข้าใจ
                  </p>
                  <address>
                    <i className="fas fa-map-marker-alt"></i>
                    สำนักงานใหญ่ 22 หมู่ 5 ตำบลดอนแก้ว อำเภอแม่ริม
                    จังหวัดเชียงใหม่ 50180
                  </address>

                  <div className="phone">
                    <BsTelephoneFill className="mr-2" />
                    <span
                      style={{ marginLeft: "10px !important" }}
                      className="ml-4"
                    >
                      0615164991
                    </span>
                  </div>
                  <div className="phone">
                    <BsTelephoneFill className="mr-2" />
                    <span
                      style={{ marginLeft: "10px !important" }}
                      className="ml-4"
                    >
                      0942865641
                    </span>
                  </div>
                  <div className="email">
                    <MdEmail />
                    <span
                      style={{ marginLeft: "10px !important" }}
                      className="ml-4"
                    >
                      {" "}
                      fieldfeel.media@gmail.com
                    </span>
                  </div>
                </div>
              </div>
            </Col>

            <Col sm={24} xs={24} md={24} lg={12} xl={12}>
              <h2 className="footer-title ">Tags</h2>
              <div className="tags">
                <div className="d-flex flex-wrap">
                  <Link
                    onClick={() => handleClickNav(cName[0])}
                    to={`/category/${cName[0]}`}
                    className="nav-link btn bg-ligth"
                  >
                    {cName[0]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[1])}
                    to={`/category/${cName[1]}`}
                    className="nav-link btn bg-ligth"
                  >
                    {cName[1]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[2])}
                    to={`/category/${cName[2]}`}
                    className="nav-link btn bg-ligth"
                  >
                    {cName[2]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[3])}
                    to={`/category/${cName[3]}`}
                    className="nav-link btn bg-ligth"
                  >
                    {cName[3]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[4])}
                    to={`/category/${cName[4]}`}
                    className="nav-link btn bg-ligth"
                  >
                    {cName[4]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[5])}
                    to={`/category/${cName[5]}`}
                    className="nav-link btn bg-ligth"
                  >
                    {cName[5]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[6])}
                    to={`/category/${cName[6]}`}
                    className="nav-link btn bg-ligth"
                  >
                    {cName[6]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[7])}
                    to={`/category/${cName[7]}`}
                    className="nav-link btn bg-ligth"
                  >
                    {cName[7]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[8])}
                    to={`/category/${cName[8]}`}
                    className="nav-link btn bg-ligth"
                  >
                    {cName[8]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[9])}
                    to={`/category/${cName[9]}`}
                    className="nav-link btn bg-ligth"
                  >
                    {cName[9]}
                  </Link>
                  <Link
                    onClick={() => handleClickNav(cName[10])}
                    to={`/category/${cName[10]}`}
                    className="nav-link btn bg-ligth"
                  >
                    {cName[10]}
                  </Link>
                  <Link to="/aboutus" className="nav-link btn bg-ligth">
                    About Us
                  </Link>
                </div>
              </div>

              <h2 className="footer-title  mt-2">Social</h2>

              <div className="taggs socail">
                <div className="d-flex flex-wrap">
                  <a
                    href="https://www.facebook.com/FieldfeelbyV"
                    target="_blank"
                    className="nav-link btn bg-ligth"
                  >
                    <BsFacebook />
                  </a>

                  <Link to="/" href="#" className="nav-link btn bg-ligth">
                    <BsTwitter />
                  </Link>
                  <Link to="/" href="#" className="nav-link btn bg-ligth">
                    <BsYoutube />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          <div className="copyrights">
            <p className="text-center text-light display-2">
              @ 2022{" "}
              <Link to="/" className="text-light">
                FIELD FEEL
              </Link>{" "}
            </p>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
