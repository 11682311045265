import React, { Children, useEffect, useRef } from "react";
import DefaultLayout from "../components/DefaultLayout ";
import SunEditor, { buttonList } from "suneditor-react";
import { uploadImage, uploadPic } from "../../utils/connect";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  createArticle,
  updateArticle,
  updateBannerArticle,
} from "../../features/article/articleSlice";
import { Button, Form, Input, Modal, message, Image, Spin, Select } from "antd";
import { useState } from "react";
import axios from "axios";
import Resizer from "react-image-file-resizer";
import { FaUser } from "react-icons/fa";
import { LoadingOutlined } from "@ant-design/icons";
import { BsCheckCircleFill } from "react-icons/bs";
import { RiCloseCircleFill } from "react-icons/ri";
import { getCategory, reset } from "../../features/category/categorySlice";
import Loading from "../../components/Loading";
let controller = null;
const { Search } = Input;
const { Option } = Select;
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const editorOptions = {
  height: 200,
  buttonList: [
    ["undo", "redo"],
    ["removeFormat"],
    ["bold", "underline", "italic", "fontSize"],
    ["fontColor", "hiliteColor"],
    ["align", "horizontalRule", "list"],
    ["table", "link", "image", "imageGallery"],
    ["showBlocks", "codeView"],
  ],
  imageRotation: false,
  fontSize: [12, 14, 16, 18, 20],
  colorList: [
    [
      "#828282",
      "#FF5400",
      "#676464",
      "#F1F2F4",
      "#FF9B00",
      "#F00",
      "#fa6e30",
      "#000",
      "rgba(255, 153, 0, 0.1)",
      "#FF6600",
      "#0099FF",
      "#74CC6D",
      "#FF9900",
      "#CCCCCC",
    ],
  ],
  imageUploadUrl: "http://localhost:8080/chazki-gateway/orders/upload",
  imageGalleryUrl: "http://localhost:8080/chazki-gateway/orders/gallery",
};

const CreateArticle = ({ isEditing }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [searchTimer, setSearchTimer] = useState(null);
  // const [isAuthor, setIsAuthor] = useState(false);
  const isAuthor = React.useRef(false);
  const isPhotoGrapher = React.useRef(false);
  const [authorName, setIsAuthorName] = useState("");
  const [ptName, setIsPtName] = useState("");

  const [media, setMedia] = useState(null);
  const [mediaPreview, setMediaPreview] = useState(null);
  const [highlighted, setHighlighted] = useState(false);
  const inputRef = useRef();

  const {
    articles,
    isLoading: LoadingArticle,
    isSuccess: success,
    isError: ErrorArticle,
    message: articleMessage,
  } = useSelector((state) => state.article);

  const [recommenderName, setRecommnenderName] = useState("");

  const authorNameInput = React.useRef();
  const ptNameInput = React.useRef();
  const leftIcon = React.useRef();
  const statusDiv = React.useRef();

  let authorNameEdit = localStorage.getItem("content-author");
  let authorTitle = localStorage.getItem("content-title");
  let authorShort_des = localStorage.getItem("content-short_des");
  let authorCategory = localStorage.getItem("content-category");

  let updateData = {
    title: authorNameEdit,
    short_desc: authorShort_des,
    category: authorCategory,
  };

  const myEditor = React.useRef(null);
  const getSunEditorInstance = (sunEditor) => {
    myEditor.current = sunEditor;
  };
  const titleref = React.useRef(null);
  const enTitleref = React.useRef(null);
  const authorref = React.useRef(null);
  const photoGrapherref = React.useRef(null);
  const descref = React.useRef(null);
  const category = React.useRef(null);
  const char = React.useRef(0);

  const dispatch = useDispatch();
  const [storystate, setStoryState] = React.useState(0);
  const src = React.useRef(null);
  const { aid } = useParams();
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const handleChange = (content) => {
    localStorage.setItem("content", content);
  };

  const { categories, isLoading, isError, message } = useSelector(
    (state) => state.category
  );
  let categories2 = [];
  if (categories) {
    categories2 = categories.flat();
  }

  const checkUsername = async (value = "") => {
    if (value.length === 0 || value.trim().length === 0) {
      if (!statusDiv) {
      }

      return;
    }

    authorNameInput.current.value = value;

    try {
      if (controller) controller.abort();

      controller = new AbortController();

      const nameA = {
        name: value,
      };

      // const res = await axios.get(
      //    `//field-feel-server.proen.app.ruk-com.cloud/api/author/name/${value}`,
      //    { value },
      //    {
      //       headers: { Authorization: user.token },
      //       signal: controller.signal,
      //    }
      // );

      const res = await axios.get(
        `http://localhost:8000/api/author/name/${value}`,
        { value },
        {
          headers: { Authorization: user.token },
          signal: controller.signal,
        }
      );

      setIsAuthorName(value);

      setRecommnenderName(res.data);
      // setIsAuthor(true);
      isAuthor.current = true;
    } catch (error) {
      //  setIsAuthor(false);
      isAuthor.current = false;
    }

    controller = null;
  };

  const checkUsernamePt = async (value = "") => {
    if (value.length === 0 || value.trim().length === 0) {
      if (!statusDiv) {
      }

      return;
    }

    ptNameInput.current.value = value;

    try {
      if (controller) controller.abort();

      controller = new AbortController();

      const nameA = {
        name: value,
      };

      // const res = await axios.get(
      //   `//field-feel-server.proen.app.ruk-com.cloud/api/photoGrapher/name/${value}`,
      //   { value },
      //   {
      //     headers: { Authorization: user.token },
      //     signal: controller.signal,
      //   }
      // );
      const res = await axios.get(
        `localhost:8000/api/photoGrapher/name/${value}`,
        { value },
        {
          headers: { Authorization: user.token },
          signal: controller.signal,
        }
      );

      setIsPtName(value);

      setRecommnenderName(res.data);
      // setIsAuthor(true);
      isPhotoGrapher.current = true;
    } catch (error) {
      //  setIsAuthor(false);
      isPhotoGrapher.current = false;
    }

    controller = null;
  };

  useEffect(() => {
    const t = document.querySelector("button[data-command='save']");
    t && (t.disabled = false);

    checkUsername(authorref.current);
    checkUsernamePt(photoGrapherref.current);
    dispatch(getCategory());

    form.setFieldsValue({
      author: authorNameEdit,
      title: authorTitle,
      short_des: authorShort_des,
      category: authorCategory,
    });

    return () => {
      dispatch(reset());
    };

    //document.querySelector("button[data-command='save']").onClick=
  }, [authorNameEdit, authorCategory, authorShort_des, authorTitle]);

  const handelChange = (e) => {
    let fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          500,
          500,
          "JPEG",
          100,
          0,
          (uri) => {
            src.current = uri;
            setMedia(uri);

            //  setMedia(uri);
          },
          "base64",
          500,
          500
        );
      } catch (err) {}
    }
  };

  const VerifyStory = () => {
    //  const images = myEditor.current.getImagesInfo();
    // if (images.length === 0) {
    //    toast.error('You must add atleast one image to your story!', {
    //       position: 'top-right',
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //    });
    //    return false;
    // }
    // if (myEditor.current.getCharCount() < 500) {
    //    console.log('char < 500');
    //    toast.error('Your story is too short!', {
    //       position: 'top-right',
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //    });
    //    return false;
    // }
    // console.log(images);
    //  src.current = images[0].src;
    char.current = myEditor.current.getCharCount();
    return true;
  };
  const checkStoryWritten = () => {
    if (!VerifyStory()) {
      return;
    }

    setStoryState(1);
    setModalOpen(true);
  };

  //  let authorNameEdit = localStorage.getItem('content-author');

  //  let authorTitle = localStorage.getItem('content-title');
  //  let authorShort_des = localStorage.getItem('content-short_des');
  //  let authorCategory = localStorage.getItem('content-category');

  const editStoryfunc = (articleData) => {
    const {
      // short_des,
      //  title,
      //  enTitle,
      html_content,
      //   category,
      //   cover,
      charcount,
      //   author,
      //   photoGrapher,
    } = articleData;

    const payload = {};

    // if (author) {
    //    payload.author = author;
    // }
    // if (enTitle) {
    //    payload.enTitle = enTitle;
    // }
    // if (photoGrapher) {
    //    payload.photoGrapher = photoGrapher;
    // }
    // if (title) {
    //    payload.title = title;
    // }
    // if (category) {
    //    payload.category = category;
    // }
    // if (short_des) {
    //    payload.short_des = short_des;
    // }
    if (html_content) {
      payload.html_content = html_content;
    }
    // if (cover) payload.cover = cover;

    if (Object.keys(payload).length === 0) {
      toast.error("You have not written anything yet", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    payload.aid = aid;
    payload.charcount = articleData.charcount;

    const callback = (res) => {
      if (res) {
        setTimeout(() => {
          window.location.replace("/articles");
        }, 100);
      } else {
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };

    dispatch(updateArticle({ payload: payload, callback: callback }));
  };

  const submitStory = async () => {
    let profilePicUrl;
    if (media !== null) {
      profilePicUrl = await uploadPic(media);
    }
    if (media !== null && !profilePicUrl) {
      toast.error("Error Uploading Image");
    }

    let payload = {};
    if (isEditing) {
      // let pTitle = null;
      // let pSD = null;
      // let pCategory = null;
      // if (titleref.current === null) {
      //    pTitle = authorTitle;
      // } else {
      //    pTitle = titleref.current;
      // }

      // if (descref.current === null) {
      //    pSD = authorShort_des;
      // } else {
      //    pSD = descref.current;
      // }

      // if (category.current === null) {
      //    pCategory = authorCategory;
      // } else {
      //    pCategory = category.current;
      // }

      payload = {
        //  title: pTitle,
        // enTitle: enTitleref.current,
        //  author: authorref.current,
        //   photoGrapher: photoGrapherref.current,
        // short_des: pSD,
        //  category: pCategory,
        html_content: localStorage.getItem("content"),
        //  cover: src.current,
        //  cover: profilePicUrl,
        charcount: char.current,
      };
    } else {
      payload = {
        title: titleref.current,
        enTitle: enTitleref.current,
        author: authorref.current,
        photoGrapher: photoGrapherref.current,
        short_des: descref.current,
        category: category.current,
        html_content: localStorage.getItem("content"),
        cover: src.current,
        //  cover: profilePicUrl,
        charcount: char.current,
      };
    }

    if (!isEditing) {
      if (!payload.title || !payload.short_des || !payload.category) {
        return toast.error("Please fill all the fields", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    // payload.categories = payload.categories.split(',');
    if (isEditing) {
      editStoryfunc(payload);
    } else {
      dispatch(
        createArticle({
          payload,
          callback: function (res, data) {
            if (res) {
              //     localStorage.setItem('content', '');
              //     window.location.replace(data);
            } else {
              toast.error("Article could not be posted", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          },
        })
      );
    }
    // dispatch(
    //    createArticle({
    //       payload,
    //       callback: function (res) {
    //          if (res) {
    //             localStorage.setItem('content', '');
    //             console.log('create success');
    //          } else {
    //             toast.error('Article could not be posted', {
    //                position: 'top-right',
    //                autoClose: 5000,
    //                hideProgressBar: false,
    //                closeOnClick: true,
    //                pauseOnHover: true,
    //                draggable: true,
    //                progress: undefined,
    //             });
    //          }
    //       },
    //    })
    // );
  };

  //   const imageUploadHandler = (xmlHttpRequest, info, core)=>{
  // 	console.log(xmlHttpRequest, info, core)
  // }
  const handleImageUploadBefore = (files, info, uploadHandler) => {
    // uploadHandler is a function
    uploadImage(files[0])
      .then((data) =>
        uploadHandler({
          result: [{ url: data, size: files[0].size, name: files[0].name }],
        })
      )
      .catch((err) => {
        uploadHandler("Image could not be uploaded");
      });
    //console.log(files, info,uploadHandler)
  };

  // const handleImageUpload = (targetImgElement, index, state, imageInfo, remainingFilesCount)=>{
  // 	console.log(targetImgElement, index, state, imageInfo, remainingFilesCount)
  // }

  const handleImageUploadError = (errorMessage, result) => {};

  const handleVideoUploadBefore = (files, info, uploadHandler) => {
    // uploadHandler is a function
  };
  const handleVideoUpload = (
    targetElement,
    index,
    state,
    info,
    remainingFilesCount
  ) => {};

  const handleVideoUploadError = (errorMessage, result) => {};

  const handleAudioUploadBefore = (files, info, uploadHandler) => {
    // uploadHandler is a function
  };

  const handleAudioUpload = (
    targetElement,
    index,
    state,
    info,
    remainingFilesCount
  ) => {};

  const goBack = () => {
    setStoryState(0);
    setTimeout(() => {
      const t = document.querySelector("button[data-command='save']");
      t && (t.disabled = false);
    }, 100);
  };
  const handleAudioUploadError = (errorMessage, result) => {};

  // const children = [];
  // categories2.map((item, index) => {
  //    children.push(<Option key={index}>{item}</Option>);
  // });

  return (
    <DefaultLayout>
      {LoadingArticle ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          {storystate === 0 ? (
            <>
              <SunEditor
                name="RockingBloggerEditor"
                height="100vh"
                width="100%"
                placeholder="เริ่มเขียนบทความ..."
                autoFocus={true}
                defaultValue={localStorage.getItem("content")}
                setDefaultStyle="font-family: 'Sarabun',  sans-serif; font-size: 16px;"
                getSunEditorInstance={getSunEditorInstance}
                onImageUploadBefore={handleImageUploadBefore}
                onChange={handleChange}
                onImageUploadError={handleImageUploadError}
                setOptions={{
                  buttonList: buttonList.complex,
                  charCounter: true,
                  charCounterType: "char",
                  callBackSave: function (contents, isChanged) {
                    checkStoryWritten();
                  },
                  font: [
                    "Arial",
                    "Calibri",
                    "Sarabun",
                    "Tahoma",
                    "Kanit",
                    "Noto Sans Thai",
                    "Pattaty",
                  ],
                }}
              />
            </>
          ) : (
            <Modal
              title="ข้อมูลบทความ"
              visible={modalOpen}
              footer={false}
              onCancel={() => {
                setModalOpen(false);
                goBack();
              }}
            >
              {isEditing ? (
                <>
                  <div className="mt-3 text-center mb-5">
                    <h5> ยืนยันการแก้ไขข้อมูล </h5>
                  </div>
                  <div className="d-flex justify-content-evenly ">
                    <Button className="btn-danger" onClick={goBack}>
                      กลับ
                    </Button>
                    <Button className="btn-primary" onClick={submitStory}>
                      โพส
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <Form
                    name="basic"
                    initialValues={{
                      title: authorTitle,
                      short_desc: authorShort_des,
                      category: authorCategory,
                    }}
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="ชื่อผู้เขียน"
                      name="author"
                      rules={[
                        {
                          required: true,
                          message: "Please input your author!",
                        },
                      ]}
                    >
                      <Input
                        placeholder={`${authorNameEdit}` && `${authorNameEdit}`}
                        status={isAuthor.current === false ? "error" : ""}
                        prefix={
                          isAuthor.current === false ? (
                            <RiCloseCircleFill />
                          ) : (
                            <BsCheckCircleFill />
                          )
                        }
                        ref={authorNameInput}
                        onChange={(e) => {
                          if (searchTimer) clearTimeout(searchTimer);
                          const { value } = e.target;
                          const noValue =
                            value.length === 0 || value.trim().length === 0;

                          if (noValue) {
                            //  setIsAuthor(false);
                            isAuthor.current = false;
                          } else {
                            //  setIsAuthor(true);
                            isAuthor.current = true;
                          }

                          authorref.current = e.target.value;
                          checkUsername(e.target.value);
                        }}
                      />
                    </Form.Item>

                    {/* <Form.Item
                     label='ชื่อช่างภาพ'
                     name='photoGrapher'
                     rules={[
                        {
                           required: true,
                           message: 'Please input your photoGrapher!',
                        },
                     ]}>
                     <Input
                        placeholder={`${ptName}`}
                        status={isPhotoGrapher.current === false ? 'error' : ''}
                        prefix={
                           isPhotoGrapher.current === false ? (
                              <RiCloseCircleFill />
                           ) : (
                              <BsCheckCircleFill />
                           )
                        }
                        ref={ptNameInput}
                        onChange={(e) => {
                           if (searchTimer) clearTimeout(searchTimer);
                           const { value } = e.target;
                           const noValue =
                              value.length === 0 || value.trim().length === 0;

                           if (noValue) {
                              //  setIsAuthor(false);
                              isPhotoGrapher.current = false;
                           } else {
                              //  setIsAuthor(true);
                              isPhotoGrapher.current = true;
                           }

                           photoGrapherref.current = e.target.value;
                           checkUsernamePt(e.target.value);
                        }}
                     />
                  </Form.Item> */}
                    <Form.Item
                      label="ชื่อบทความ-อังกฤษ"
                      name="enTitle"
                      rules={[
                        {
                          required: true,
                          message: "Please input your EN-title!",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => (enTitleref.current = e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item
                      label="ชื่อบทความ"
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: "Please input your title!",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => (titleref.current = e.target.value)}
                      />
                    </Form.Item>

                    <Form.Item
                      label="คำอธิบายเบื้องต้น"
                      name="short_desc"
                      rules={[
                        {
                          required: true,
                          message: "Please input your short_desc!",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => (descref.current = e.target.value)}
                      />
                    </Form.Item>

                    {/* <Form.Item
                     label='ประเภท'
                     name='category'
                     rules={[
                        {
                           required: true,
                           message: 'Please input your category!',
                        },
                     ]}>
                     <Input
                        onChange={(e) => (category.current = e.target.value)}
                     />
                  </Form.Item> */}

                    <Form.Item label="ประเภท" name="category">
                      <Select
                        style={{
                          width: 120,
                        }}
                        defaultValue={category}
                        onChange={(value) => (category.current = value)}
                      >
                        {categories2.map((e, index) => (
                          <Option de value={e.name} key={index}>
                            {e.name}{" "}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item label="รูปภาพ" name="cover">
                      <Input onChange={(e) => (src.current = e.target.value)} />
                    </Form.Item>

                    <input
                      name="imgUpload"
                      accept="image/*"
                      type="file"
                      onChange={handelChange}
                    />
                    <br />
                    <Image src={media} width={100} />
                    <br />
                  </Form>
                  <div className="d-flex justify-content-evenly ">
                    <Button className="btn-danger" onClick={goBack}>
                      กลับ
                    </Button>
                    <Button
                      onClick={submitStory}
                      disabled={isAuthor.current === false}
                    >
                      โพส
                    </Button>
                  </div>
                </>
              )}
            </Modal>
          )}
        </>
      )}

      <ToastContainer />
    </DefaultLayout>
  );
};

export default CreateArticle;
