import {
    Avatar,
    Button,
    Col,
    Form,
    Image,
    Input,
    List,
    Modal,
    Row,
 } from 'antd';
 import React, { useEffect, useRef, useState } from 'react';
 import { Container } from 'react-bootstrap';
 import { useDispatch, useSelector } from 'react-redux';
 import { useLocation, useNavigate } from 'react-router-dom';
 import Article from '../components/Article';
 import Footer from '../components/Footer';
 import Header from '../components/Header';
 import styled from 'styled-components';
 import {
    getArticlesByAuthor,
    reset,
    searchArticles,
 } from '../features/article/articleSlice';
 import { getAuthorById } from '../features/author/authorSlice';
import { getPhotoGrapherDetail } from '../features/photoGrapher/photoGrapherSlice';
import { getArticlesByPhotoGrapher } from '../features/article/articleSlice';
 
 const PhotoGrapherSceen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
 
    const { state } = location;
 
    const [loadigPage, setLoadingPage] = useState(true);
 
    const { articles, isLoading, isError, message } = useSelector(
       (state) => state.article
    );
    const { photoGrapher } = useSelector((state) => state.photoGrapher);

    console.log(state);
 
    useEffect(() => {
       if (loadigPage) {
          setTimeout(() => {
             setLoadingPage(false);
          }, 1500);
       }
 
       dispatch(getPhotoGrapherDetail(state.id));
       dispatch(getArticlesByPhotoGrapher(state.id));
    }, []);
 
    const [isModalOpen, setModalOpen] = useState(false);
    const textSearch = useRef();
    const [loading, setLoading] = useState(false);
    const openModal = () => {
       setModalOpen(true);
    };
 
    const handleCancel = () => {
       setModalOpen(false);
    };
 
    const handleOk = () => {
       dispatch(searchArticles(textSearch.current));
       navigate(`/search/${textSearch.current}`);
       setLoading(true);
       setTimeout(() => {
          setLoading(false);
          setModalOpen(false);
       }, 3000);
    };
 
    return (
       <>
          <Header openModal={openModal} />
 
          <br />
          <Container>
             {/* <HeaderName>
                <h1> {state.authorName} </h1>
             </HeaderName> */}
 
             <Row className='mt-4 mb-4' gutter={10}>
                <Col span={8}>
                   {' '}
                  {photoGrapher.picUrl ? (
                     <Image
                     preview={false}
                     style={{
                        borderRadius: '10px',
                        marginRight: '40px !important',
                     }}
                     width={200}
                     src={photoGrapher.picUrl.url}
                  />
                  ) : (
                    <Image
                    preview={false}
                    style={{
                       borderRadius: '10px',
                       marginRight: '40px !important',
                    }}
                    width={200}
                    src={'https://cdn-icons-png.flaticon.com/512/44/44948.png?w=740&t=st=1676807992~exp=1676808592~hmac=b1201a2ee4cadb3fe9e0258ffaad999cb732b94058fb22cd729bf7e89b6ec757'}
                 />
                  )}
                </Col>
                <Col span={8}>
                   {' '}
                   <div style={{ textAlign: 'left' }}>
                      <h2> {photoGrapher.name} </h2>
                      <p> {photoGrapher.bio} </p>
                   </div>
                </Col>
             </Row>
 
             <hr />
 
             <div className='site-card-wrapper'>
                <Row gutter={[20, 50]}>
                   {/* {article.map(
                         (i) =>
                          
                            (
                               <Col xs={24} md={12} xl={8}>
                                  <Article key={i._id} i={i} />
                               </Col>
                            )
                      )} */}
 
                   {articles.map((article) => {
                      return (
                         <Col key={article._id} xs={24} md={12} xl={8}>
                            <Article
                               article={article}
                               author={state.authorName}
                            />
                         </Col>
                      );
                   })}
                </Row>
             </div>
          </Container>
          <br />
          <br />
          <Footer />
          <Modal
             title='ค้นหา'
             visible={isModalOpen}
             footer={[
                <Button
                   key='submit'
                   type='primary'
                   loading={loading}
                   onClick={handleOk}>
                   ค้นหา
                </Button>,
             ]}
             onCancel={handleCancel}>
             <Form>
                <Form.Item label='ค้นหา' name='username'>
                   <Input
                      onChange={(e) => (textSearch.current = e.target.value)}
                   />
                </Form.Item>
             </Form>
          </Modal>
       </>
    );
 };
 
 const HeaderName = styled.div`
    padding: 20px;
    border: 10px solid black;
 `;
 
 export default PhotoGrapherSceen;
 