import axios from 'axios';

// const API_URL = 'http://localhost:8000/api/photoGrapher/';
//const API_URL = "//field-feel-server.proen.app.ruk-com.cloud/api/photoGrapher/";

let API_URL = '';

if (process.env.NODE_ENV === 'development') {
  API_URL = 'http://localhost:8000/api/photoGrapher/';
} else {
  API_URL = '//api.field-feel.com/api/photoGrapher/';
}

const createPhotoGrahper = async ({ payload, callback }, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.post(API_URL, payload, config);

  if (res.status === 200) {
    callback(true);
    return;
  }
  callback(false);
  return res.data;
};

const getPhotoGrapher = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL, config);

  if (response.status === 401) {
    localStorage.removeItem('userInfo');
  }

  return response.data;
};

const getPhotoGrapherDetail = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL + id, config);

  if (response.status === 401) {
    localStorage.removeItem('userInfo');
  }

  return response.data;
};

// GET NAME
export const getNamePhotoGrapher = async (name) => {
  const response = await axios.get(API_URL + '/name/' + name);

  return response.data;
};

// Delete
const deletePhotoGrapher = async ({ payload, callback }, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.delete(API_URL + payload, config);

  if (res.status === 200) {
    callback(true);
    return true;
  }
  callback(false, null);
  return false;
};

const updatePhotoGrapher = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token.token}`,
    },
  };

  const response = await axios.put(API_URL + `${data.userId}`, data, config);

  return response.data;
};

const photoGrapherService = {
  createPhotoGrahper,
  getPhotoGrapher,
  getNamePhotoGrapher,
  updatePhotoGrapher,
  deletePhotoGrapher,
  getPhotoGrapherDetail,
};

export default photoGrapherService;
