import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, Modal, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import DefaultLayout from '../components/DefaultLayout ';

import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import { MdDeleteForever, MdModeEdit } from 'react-icons/md';
import { RiNumbersFill } from 'react-icons/ri';
import {
   createDepartment,
   getDepartment,
   reset,
   updateDepartment,
} from '../../features/department/departmentSlice';

const DepartmentPage = () => {
   const dispatch = useDispatch();
   const [isSuccess, setIsSuccess] = useState(false);
   const [modalOpen, setModalOpen] = useState(false);
   const [modalEditOpen, setModalEditOpen] = useState(false);
   const [nameCategory, setNameCategory] = useState('');
   const did = React.useRef();
   const [loadigPage, setLoadingPage] = useState(true);

   const { departments, isLoading, isError, message } = useSelector(
      (state) => state.department
   );

   let department2 = [];
   if (departments) {
      department2 = departments.flat();
   }



   useEffect(() => {
      if (loadigPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }
      dispatch(getDepartment());

      return () => {
         dispatch(reset());
      };
   }, []);

   const onFinish = (values) => {
      if (!modalEditOpen) {
         const callback = (res) => {
           
            if (res) {
               setIsSuccess(true);
               setModalOpen(false);
               toast.success('เพิ่มข้อมูลสำเร็จ', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
               });
            } else {
             
               toast.error('ไม่สำเร็จ');
            }
         };

         dispatch(createDepartment({ payload: values, callback: callback }));
         window.location.reload();
      } else {
         values._id = did.current;
         dispatch(updateDepartment(values));
         window.location.reload();
      }
   };

   //    const handleDelete = (id) => {
   //       console.log(id);
   //       if (window.confirm('Are you sure')) {
   //          const callback = (res) => {
   //             if (res) {
   //                setIsSuccess(true);
   //                toast.success('ลบข้อมูลสำร็จ', {
   //                   position: 'top-right',
   //                   autoClose: 5000,
   //                   hideProgressBar: false,
   //                   closeOnClick: true,
   //                   pauseOnHover: true,
   //                   draggable: true,
   //                   progress: undefined,
   //                   theme: 'light',
   //                });
   //             } else {
   //                toast.error('ไม่สำเร็จ');
   //             }
   //          };

   //          dispatch(deleteDepartment({ payload: id, callback: callback }));
   //       }
   //    };

   const handleEdit = (value) => {
      setModalEditOpen(true);
      did.current = value._id;
      setNameCategory(value.name);
   };

   return (
      <DefaultLayout>
         {isError &&
            toast.error(message, {
               position: 'top-right',
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: 'light',
            })}
         {loadigPage ? (
            <Loading />
         ) : (
            <>
               <div className='d-flex justify-content-between'>
                  <h3> รายการหมวดหมู่ </h3>
                  <Button type='primary' onClick={() => setModalOpen(true)}>
                     {' '}
                     สร้างหมวดหมู่{' '}
                  </Button>
               </div>
               <Row gutter={[26, 26]}>
                  {department2.map((department) => {
                     return (
                        <Col xs={24} md={12} xl={8}>
                           <Card
                              className='text-capitalize'
                              title={department.name}
                              hoverable
                              bordered={false}
                              actions={[
                                 <MdDeleteForever
                                    style={{ fontSize: '32px !important' }}
                                    key='delete'
                                 />,
                                 <MdModeEdit
                                    key='edit'
                                    onClick={() => handleEdit(department)}
                                 />,
                              ]}>
                              <RiNumbersFill
                                 style={{
                                    fontSize: '32px !important',
                                    color: 'blue',
                                 }}
                              />
                           </Card>
                        </Col>
                     );
                  })}
               </Row>
            </>
         )}
         {modalOpen && (
            <Modal
               title='สร้างหมวดหมู่'
               visible={modalOpen}
               footer={false}
               onCancel={() => {
                  setModalOpen(false);
               }}>
               <Form layout='vertical' onFinish={onFinish}>
                  <Form.Item name='name' label='หมวดหมู่'>
                     <Input />
                  </Form.Item>

                  <div className='d-flex justify-content-end'>
                     <Button htmlType='submit' type='primary'>
                        {' '}
                        SAVE
                     </Button>
                  </div>
               </Form>
            </Modal>
         )}
         {modalEditOpen && (
            <Modal
               title='แก้ไข'
               visible={modalEditOpen}
               footer={false}
               onCancel={() => {
                  setModalEditOpen(false);
               }}>
               <Form layout='vertical' onFinish={onFinish}>
                  <Form.Item name='name' label='หมวดหมู่'>
                     <Input placeholder={nameCategory} />
                  </Form.Item>

                  <div className='d-flex justify-content-end'>
                     <Button htmlType='submit' type='primary'>
                        {' '}
                        SAVE
                     </Button>
                  </div>
               </Form>
            </Modal>
         )}
      </DefaultLayout>
   );
};

export default DepartmentPage;
