import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import DefaultLayout from '../components/DefaultLayout ';
import Loading from '../../components/Loading';
import { AiOutlineInbox } from 'react-icons/ai';
import {
   createAuthor,
   deleteAuthor,
   getAuthors,
   reset,
} from '../../features/author/authorSlice';
import { uploadImage, uploadPic } from '../../utils/connect';
import { Button, Form, Input, Modal, Space, Table, Upload } from 'antd';
import ImageDropDiv from '../components/ImageDropDiv';
import { toast } from 'react-toastify';
import Resizer from 'react-image-file-resizer';
import {
   createUser,
   deleteUser,
   getUsers,
} from '../../features/users/userSlice';

const AdminPage = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const [isSuccess, setIsSuccess] = useState(false);

   const [modalOpen, setModalOpen] = useState(false);
   const [editUser, setEditUser] = useState(null);

   const [loadigPage, setLoadingPage] = useState(true);

   const { user } = useSelector((state) => state.auth);

   const { users, isLoading, isError, message } = useSelector(
      (state) => state.user
   );

   let authorsData = null;
   if (users) {
      authorsData = users.flat();
   }

   const [media, setMedia] = useState(null);
   const [mediaPreview, setMediaPreview] = useState(null);
   const [highlighted, setHighlighted] = useState(false);
   const inputRef = useRef();

   useEffect(() => {
      if (loadigPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }
      if (!user) {
         navigate('/login');
      }

      dispatch(getUsers());

      return () => {
         dispatch(reset());
      };
   }, []);

   const columns = [
      {
         title: 'ชื่อ',
         dataIndex: ['username', '_id'],
         key: 'username',
         width: '20%',
         render: (text, record) => (
            <p
               style={{ cursor: 'pointer' }}
               onClick={() =>
                  navigate('/admin/adminprofile', {
                     state: {
                        record: record['_id'],
                     },
                  })
               }>
               {record['username']}
            </p>
         ),
      },

      {
         title: 'อีเมล์',
         dataIndex: 'email',
         key: 'email',
      },
      {
         title: 'วันที่',
         dataIndex: 'createdAt',
         render: (createdAt) => (
            <div> {createdAt.toString().substring(0, 10)} </div>
         ),
      },
      {
         title: 'Action',
         key: 'action',
         render: (_, record) => (
            <Space size='middle'>
               <p
                  style={{ cursor: 'pointer' }}
                  onClick={() => deleteHanler(record)}>
                  Delete
               </p>
            </Space>
         ),
      },
   ];

   const onFinish = async (values, image) => {
      const callback = (res) => {
         
         if (res) {
            setIsSuccess(true);
            setModalOpen(false);
            toast.success('เพิ่มข้อมูลสำเร็จ', {
               position: 'top-right',
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: 'light',
            });
         }
      };
      dispatch(createUser({ payload: values, callback: callback }));
   };

   const deleteHanler = (record) => {
      if (window.confirm('Are you sure')) {
         const callback = (res) => {
            if (res) {
               setIsSuccess(true);
               toast.success('ลบข้อมูลแอดมิน', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
               });
            } else {
               toast.error('ไม่สำเร็จ');
            }
         };

         const aid = record._id;

         dispatch(deleteUser({ payload: aid, callback: callback }));
      }
   };

   return (
      <DefaultLayout>
         {loadigPage && <Loading />}
         <div className='d-flex justify-content-between'>
            <h3> รายการบทความทั้งหมด </h3>
            <Button type='primary' onClick={() => setModalOpen(true)}>
               {' '}
               เพิ่มแอดมิน
            </Button>
         </div>
         <Table columns={columns} dataSource={authorsData} />;
         {modalOpen && (
            <Modal
               title={`${editUser !== null ? 'Edit Admin' : 'Add New Admin'}`}
               visible={modalOpen}
               footer={false}
               onCancel={() => {
                  setModalOpen(false);
               }}>
               <Form
                  layout='vertical'
                  initialValues={editUser}
                  onFinish={onFinish}>
                  <Form.Item name='username' label='ชื่อ-สกุล'>
                     <Input />
                  </Form.Item>

                  <Form.Item name='email' label='อีเมล์'>
                     <Input />
                  </Form.Item>
                  <Form.Item name='password' label='รหัสผ่าน'>
                     <Input />
                  </Form.Item>

                  <div className='d-flex justify-content-end'>
                     <Button block htmlType='submit' type='primary'>
                        {' '}
                        SAVE
                     </Button>
                  </div>
               </Form>
            </Modal>
         )}
      </DefaultLayout>
   );
};

export default AdminPage;
