import { Button, message, Modal, Space, Switch, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import DefaultLayout from '../components/DefaultLayout ';
import {
   deleteArticle,
   getArticles,
   removePhotographerArticle,
   reset,
   updateBannerArticle,
} from '../../features/article/articleSlice';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { BsCheckLg } from 'react-icons/bs';
import { MdOutlineClose } from 'react-icons/md';

const Articles = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const [modalAP, setModalAP] = useState(false);

   const [loadigPage, setLoadingPage] = useState(true);
   const [isSuccess, setIsSuccess] = useState(false);

   const [APdata, setAPdata] = useState([])

   const { user } = useSelector((state) => state.auth);
   const {
      articles,
      isLoading,
      isSuccess: success,
      updateBannerSuccess,
      isError,
      message: articleMessage,
   } = useSelector((state) => state.article);

   const deleteHanler = (record) => {
      if (window.confirm('Are you sure')) {
         const callback = (res) => {
            if (res) {
               setIsSuccess(true);
               message.success('article deleted');
               window.location.reload();
            } else {
               toast.error('ไม่สำเร็จ');
            }
         };

         const aid = record._id;

         dispatch(deleteArticle({ payload: aid, callback: callback }));
      }
   };

   const handleSwitchChange = (id) => {
      dispatch(updateBannerArticle(id));
  
   };

   useEffect(() => {
      if (loadigPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }

      if (isError) {
        
      }

      localStorage.setItem('content', ' ');
      localStorage.setItem('content-category', ' ');
      localStorage.setItem('content-author', ' ');
      localStorage.setItem('content-title', ' ');
      localStorage.setItem('content-short_des', ' ');

      if (!user) {
         navigate('/login');
      }

      dispatch(getArticles());



      return () => {
         dispatch(reset());
      };
   }, [dispatch, isSuccess, updateBannerSuccess]);

   const EditStory = (record) => {
      if (record) {
         localStorage.setItem('content', record.html_content);
         localStorage.setItem('content-author', record.author.name);
         localStorage.setItem('content-title', record.title);
         localStorage.setItem('content-enTitle', record.enTitle);
         localStorage.setItem('content-short_des', record.short_des);
         localStorage.setItem('content-category', record.category);

         //     localStorage.setItem('content', record);
         setTimeout(() => {
            navigate(`/editArticle/${record._id}`);
         }, 100);
      }
   };

   const EditAuthor = (record) =>{
  
   }
   const EditPhotoGrapher = (record) =>{
   
      dispatch(removePhotographerArticle(record._id))
   }


  // FILTER isBanner //

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  // FILTER isBanner END //

   const columns = [
      {
         title: 'ชื่อบทความ',
         dataIndex: 'title',
         key: 'title',
         width: '20%',
         render: (value, record) => (
            <a
               onClick={() =>
                  navigate('/admin/article', {
                     state: {
                        record: record,
                     },
                  })
               }>
               {value.length > 25 ? `${value.substring(0, 25)} ...` : value}
            </a>
         ),
      },
      {
         title: 'ชื่อบทความ-อังกฤษ',
         dataIndex: 'enTitle',
         key: 'enTitle',
         width: '10%',
         //...getColumnSearchProps('userId'),
       
      },
      {
         title: 'Image',
         dataIndex: 'cover',
         width: '10%',
         render: (cover, record) => (
            <img
               src={cover}
               alt=''
               style={{ objectFit: 'fill' }}
               height='100'
               width='100'
            />
         ),
      },
      {
         title: 'ผู้เขียน',
         dataIndex: 'author',
         key: 'author',
         width: '15%',
         render: (record) => <p> {record.name} </p>,
      },
      // {
      //    title: 'คำอธิบาย',
      //    dataIndex: 'short_des',
      //    key: 'short_des',
      //    width: '20%',
      //    render: (record) => (
      //       <div>
      //          {' '}
      //          {record.length > 95 ? `${record.substring(0, 95)} ...` : record}
      //       </div>
      //    ),
      // },
      // {
      //    title: 'เวลาในการอ่าน',
      //    dataIndex: 'readingTime',
      //    key: 'readingTime',
      // },
      {
         title: 'category',
         dataIndex: 'category',
         key: 'category',
         width: '10%',
      },
      {
         title: 'วันที่',
         width: '10%',
         dataIndex: 'createdAt',
         render: (createdAt) => (
            <div> {createdAt.toString().substring(0, 10)} </div>
         ),
      },
      {
         title: "แบนเนอร์",
         dataIndex: "isBanner",
         width: '10%',
         filters: [
           {
             text: "แบนเนอร์",
             value: true,
           },
           {
             text: "ไม่ใช่ แบนเนอร์",
             value: false,
           },
         ],
   
         filteredValue: filteredInfo.isBanner || null,
         onFilter: (value, record) => record.isBanner === value,
         sorter: (a, b) => a.isBanner.length - b.isBanner.length,
         sortOrder: sortedInfo.columnKey === "isBanner" ? sortedInfo.order : null,
         ellipsis: true,
   
         render: (record,row) => (
           <>
   
             {
               (record  ? (
                 <>
                   <Switch
                     onChange={() => handleSwitchChange(row._id)}
                     checked
                     checkedChildren={<BsCheckLg />}
                     unCheckedChildren={<MdOutlineClose />}
                   />
                 </>
               ) : (
                 <>
                   <Switch
                     onChange={() => handleSwitchChange(row._id)}
                   
                     checkedChildren={<BsCheckLg />}
                     unCheckedChildren={<MdOutlineClose />}
                   />
                 </>
               ))
             }
           </>
         ),
       },
      // {
      //    title: 'แก้ไขผู้เขียนและช่างภาพ',
      //    render: (_, record) => (
      //       <Space size='middle'>
      //          <b
      //             style={{ cursor: 'pointer' }}
      //             onClick={() => EditAuthor(record)}>
      //             ผู้เขียน
      //          </b>
      //          <b
      //             style={{ cursor: 'pointer' }}
      //             onClick={() => EditPhotoGrapher(record)}>
      //             ช่างภาพ
      //          </b>
      //       </Space>
      //    ),
      // },
      {
         title: 'Action',
         key: 'action',
         render: (_, record) => (
            <Space size='middle'>
               {/* <b 
                 style={{ cursor: 'pointer' }}
                 onClick={() =>
                  
           {
            setAPdata(record)
            setModalAP(true)

           }
               
               }
               >
               แก้ไขข้อมูล
               </b> */}
                 <Link 
                 to={`/admin/editArticleData/${record._id}`}
                 >
                 <b 
                 style={{ cursor: 'pointer' }}
                
               >
               แก้ไขข้อมูล
               </b>
                 </Link>
               <b
                  style={{ cursor: 'pointer' }}
                  onClick={() => EditStory(record)}>
                  แก้ไขเนื้อหาบทความ
               </b>
               <b
                  style={{ cursor: 'pointer' }}
                  onClick={() => deleteHanler(record)}>
                  ลบ
               </b>
            </Space>
         ),
      },
   ];

   const handleChange = (pagination, filters, sorter) => {
      console.log("Various parameters", pagination, filters, sorter);
      setFilteredInfo(filters);
      setSortedInfo(sorter);
    };

   return (
      <DefaultLayout>
         {isLoading ? (
            <Loading />
         ) : (
            <>
               <div className='d-flex justify-content-between'>
                  <h3> รายการบทความทั้งหมด </h3>
                  <Button
                  className='btn-primary'
                     type='primary'
                     onClick={() => navigate('/createArticle')}>
                     {' '}
                     สร้างบทความ{' '}
                  </Button>
               </div>
               <Table columns={columns} dataSource={articles}    onChange={handleChange} />;
            </>
         )}
           <Modal title="Basic Modal" 
            visible={modalAP}
            footer={false}
            onCancel={() =>
            setModalAP(false)}


           >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal>
      </DefaultLayout>
   );
};

export default Articles;
