import { Button, Card, Col, Form, Input, Modal, Row } from "antd";

import React, { useEffect, useRef, useState } from "react";
import Container from "../components/Container";
import Footer from "../components/Footer";
import HeroCarousel from "../components/HeroCarousel";
import { AiOutlineUser } from "react-icons/ai";
import PopularNews from "../components/Home/PopularNews";
import Article from "../components/Article";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  getArticles,
  getArticlesBanner,
  reset,
  searchArticles,
} from "../features/article/articleSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../components/Loading";
import SmallCard from "../components/SmallCard";
import { Helmet } from "react-helmet";
import { getCategory } from "../features/category/categorySlice";
import Meta from "../components/Meta";

import tImage from "../assets/img/loginPage.jpg";

const gridStyle = {
  width: "25%",
  textAlign: "center",
};

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setModalOpen] = useState(false);
  const {
    articles: articleData,
    articlesBanner,
    isLoading,
    isError,
    message,
  } = useSelector((state) => state.article);

  const { categories } = useSelector((state) => state.category);

  let articles = [];
  let articles2 = [];
  const [loading, setLoading] = useState(false);
  const [loadigPage, setLoadingPage] = useState(true);

  const textSearch = useRef();

  useEffect(() => {
    if (loadigPage) {
      setTimeout(() => {
        setLoadingPage(false);
      }, 1500);
    }

    dispatch(getArticles());
    dispatch(getArticlesBanner());
    dispatch(getCategory());

    return () => {
      dispatch(reset());
    };
  }, []);

  if (articleData) {
    articles = articleData.slice(0, 6);
    articles2 = articleData.slice(0, 5);
  }

  const cName = categories.map((e) => e.name);

  const openModal = () => {
    setModalOpen(true);
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const handleOk = () => {
    dispatch(searchArticles(textSearch.current));
    navigate(`/search/${textSearch.current}`);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setModalOpen(false);
    }, 3000);
  };

  return (
    <>
      <ToastContainer />
      <Header openModal={openModal} />
      {loadigPage ? (
        <Loading />
      ) : (
        <>
          <div>
            <br />

            <HeroCarousel articles={articlesBanner} />
          </div>

          <br />
          <br />
          <Container>
            <div>
              <div className="site-card-wrapper">
                <h3 className="text-left font-weight-bold">
                  <b>Lates</b>
                </h3>
                <br />
                <Row gutter={[20, 50]}>
                  {articles.map((article) => {
                    return (
                      <Col key={article._id} xs={24} md={12} xl={8}>
                        <Article
                          article={article}
                          author={article.author.name}
                          isLoading={isLoading}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
            <hr />
            <br />
            {/* <PopularNews /> */}

            <div>
              <Link to={`/category/${cName[0]}`} className="nav-link2">
                <h3 className="text-left font-weight-bold">
                  <b> {cName[0]} </b>
                </h3>
              </Link>

              <div className="site-card-wrapper">
                <Row gutter={[20, 50]}>
                  {/* {article.map(
                        (i) =>
                         
                           (
                              <Col xs={24} md={12} xl={8}>
                                 <Article key={i._id} i={i} />
                              </Col>
                           )
                     )} */}

                  {articleData
                    .filter((i) => i.category === cName[0])
                    .map((article) => {
                      return (
                        <Col key={article._id} xs={24} md={12} xl={8}>
                          <Article
                            author={article.author.name}
                            article={article}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </div>
            <hr />
            <br />
            <div>
              <Link to={`/category/${cName[1]}`} className="nav-link2">
                <h3 className="text-left font-weight-bold">
                  <b> {cName[1]} </b>
                </h3>
              </Link>

              <div className="site-card-wrapper">
                <Row gutter={[20, 50]}>
                  {/* {article.map(
                        (i) =>
                         
                           (
                              <Col xs={24} md={12} xl={8}>
                                 <Article key={i._id} i={i} />
                              </Col>
                           )
                     )} */}

                  {articleData
                    .filter((i) => i.category === cName[1])
                    .map((article) => {
                      return (
                        <Col key={article._id} xs={24} md={12} xl={8}>
                          <Article
                            author={article.author.name}
                            article={article}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </div>
              <br />
            </div>
            <hr />
            <br />
            <div>
              <Link to={`/category/${cName[2]}`} className="nav-link2">
                <h3 className="text-left font-weight-bold">
                  <b>{cName[2]} </b>
                </h3>
              </Link>

              <div className="site-card-wrapper">
                <Row gutter={[20, 50]}>
                  {/* {article.map(
                        (i) =>
                         
                           (
                              <Col xs={24} md={12} xl={8}>
                                 <Article key={i._id} i={i} />
                              </Col>
                           )
                     )} */}

                  {articleData
                    .filter((i) => i.category === cName[2])
                    .map((article) => {
                      return (
                        <Col key={article._id} xs={24} md={12} xl={8}>
                          <Article
                            author={article.author.name}
                            article={article}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </div>
              <hr />
              <br />
            </div>

            <div>
              <Link to={`/category/${cName[3]}`} className="nav-link2">
                {" "}
                <h3 className="text-left font-weight-bold">
                  <b> {cName[3]} </b>
                </h3>
              </Link>

              <div className="site-card-wrapper">
                <Row gutter={[20, 50]}>
                  {/* {article.map(
                        (i) =>
                         
                           (
                              <Col xs={24} md={12} xl={8}>
                                 <Article key={i._id} i={i} />
                              </Col>
                           )
                     )} */}

                  {articleData
                    .filter((i) => i.category === cName[3])
                    .map((article) => {
                      return (
                        <Col key={article._id} xs={24} md={12} xl={8}>
                          <Article
                            author={article.author.name}
                            article={article}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </div>
              <hr />
              <br />
            </div>
            <div>
              <Link to={`/category/${cName[4]}`} className="nav-link2">
                <h3 className="text-left font-weight-bold">
                  <b> {cName[4]} </b>
                </h3>
              </Link>

              <div className="site-card-wrapper">
                <Row gutter={[20, 50]}>
                  {/* {article.map(
                        (i) =>
                         
                           (
                              <Col xs={24} md={12} xl={8}>
                                 <Article key={i._id} i={i} />
                              </Col>
                           )
                     )} */}

                  {articleData
                    .filter((i) => i.category === cName[4])
                    .map((article) => {
                      return (
                        <Col key={article._id} xs={24} md={12} xl={8}>
                          <Article
                            author={article.author.name}
                            article={article}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </div>
              <hr />
              <br />
            </div>

            <div>
              <Link to={`/category/${cName[5]}`} className="nav-link2">
                {" "}
                <h3 className="text-left font-weight-bold">
                  {" "}
                  <b> {cName[5]} </b>
                </h3>{" "}
              </Link>{" "}
              <div className="site-card-wrapper">
                <Row gutter={[20, 50]}>
                  {/* {article.map(
                        (i) =>
                         
                           (
                              <Col xs={24} md={12} xl={8}>
                                 <Article key={i._id} i={i} />
                              </Col>
                           )
                     )} */}

                  {articleData
                    .filter((i) => i.category === cName[5])
                    .map((article) => {
                      return (
                        <Col key={article._id} xs={24} md={12} xl={8}>
                          <Article
                            author={article.author.name}
                            article={article}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </div>
              <hr />
              <br />
            </div>

            <div>
              <Link to={`/category/${cName[6]}`} className="nav-link2">
                {" "}
                <h3 className="text-left font-weight-bold">
                  {" "}
                  <b> {cName[6]} </b>
                </h3>{" "}
              </Link>{" "}
              <div className="site-card-wrapper">
                <Row gutter={[20, 50]}>
                  {/* {article.map(
                        (i) =>
                         
                           (
                              <Col xs={24} md={12} xl={8}>
                                 <Article key={i._id} i={i} />
                              </Col>
                           )
                     )} */}

                  {articleData
                    .filter((i) => i.category === cName[6])
                    .map((article) => {
                      return (
                        <Col key={article._id} xs={24} md={12} xl={8}>
                          <Article
                            author={article.author.name}
                            article={article}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </div>
              <hr />
              <br />
            </div>

            <div>
              <Link to={`/category/${cName[7]}`} className="nav-link2">
                {" "}
                <h3 className="text-left font-weight-bold">
                  {" "}
                  <b> {cName[7]} </b>
                </h3>{" "}
              </Link>{" "}
              <div className="site-card-wrapper">
                <Row gutter={[20, 50]}>
                  {/* {article.map(
                        (i) =>
                         
                           (
                              <Col xs={24} md={12} xl={8}>
                                 <Article key={i._id} i={i} />
                              </Col>
                           )
                     )} */}

                  {articleData
                    .filter((i) => i.category === cName[7])
                    .map((article) => {
                      return (
                        <Col key={article._id} xs={24} md={12} xl={8}>
                          <Article
                            author={article.author.name}
                            article={article}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </div>
              <hr />
              <br />
            </div>

            <div>
              <Link to={`/category/${cName[8]}`} className="nav-link2">
                {" "}
                <h3 className="text-left font-weight-bold">
                  {" "}
                  <b> {cName[8]} </b>
                </h3>{" "}
              </Link>{" "}
              <div className="site-card-wrapper">
                <Row gutter={[20, 50]}>
                  {/* {article.map(
                        (i) =>
                         
                           (
                              <Col xs={24} md={12} xl={8}>
                                 <Article key={i._id} i={i} />
                              </Col>
                           )
                     )} */}

                  {articleData
                    .filter((i) => i.category === cName[8])
                    .map((article) => {
                      return (
                        <Col key={article._id} xs={24} md={12} xl={8}>
                          <Article
                            author={article.author.name}
                            article={article}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </div>
              <hr />
              <br />
            </div>

            <div>
              <Link to={`/category/${cName[9]}`} className="nav-link2">
                {" "}
                <h3 className="text-left font-weight-bold">
                  {" "}
                  <b> {cName[9]} </b>
                </h3>{" "}
              </Link>{" "}
              <div className="site-card-wrapper">
                <Row gutter={[20, 50]}>
                  {/* {article.map(
                        (i) =>
                         
                           (
                              <Col xs={24} md={12} xl={8}>
                                 <Article key={i._id} i={i} />
                              </Col>
                           )
                     )} */}

                  {articleData
                    .filter((i) => i.category === cName[9])
                    .map((article) => {
                      return (
                        <Col key={article._id} xs={24} md={12} xl={8}>
                          <Article
                            author={article.author.name}
                            article={article}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </div>
              <hr />
              <br />
            </div>

            <div>
              <Link to={`/category/${cName[10]}`} className="nav-link2">
                {" "}
                <h3 className="text-left font-weight-bold">
                  {" "}
                  <b> {cName[10]} </b>
                </h3>{" "}
              </Link>{" "}
              <div className="site-card-wrapper">
                <Row gutter={[20, 50]}>
                  {/* {article.map(
                        (i) =>
                         
                           (
                              <Col xs={24} md={12} xl={8}>
                                 <Article key={i._id} i={i} />
                              </Col>
                           )
                     )} */}

                  {articleData
                    .filter((i) => i.category === cName[10])
                    .map((article) => {
                      return (
                        <Col key={article._id} xs={24} md={12} xl={8}>
                          <Article
                            author={article.author.name}
                            article={article}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </div>
              <hr />
              <br />
            </div>
          </Container>
          <br />
          <Footer />
          <Modal
            title="ค้นหา"
            visible={isModalOpen}
            footer={[
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={handleOk}
              >
                ค้นหา
              </Button>,
            ]}
            onCancel={handleCancel}
          >
            <Form>
              <Form.Item label="ค้นหา" name="username">
                <Input
                  onChange={(e) => (textSearch.current = e.target.value)}
                />
              </Form.Item>
            </Form>
          </Modal>
        </>
      )}
    </>
  );
};

export default HomePage;
