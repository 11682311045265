import React from "react";
import { Card, Col, Row, Skeleton } from "antd";
import { Link } from "react-router-dom";

const { Meta } = Card;

const Article = ({ article, author, isLoading }) => {
  return (
    <>
      {" "}
      {article && (
        <Link
          // to={{
          //    pathname: `/article/${articles._id}`,
          //    state: { articleId: articles._id }
          // }}
          to={`/${article.enTitle}`}
          state={{ articleId: article.enTitle }}
        >
          {" "}
          <Card
            style={{ height: "500px" }}
            hoverable
            bordered={false}
            cover={
              <img
                alt="img 1"
                style={{ height: "200px" }}
                src={article.cover}
              />
            }
          >
            <Skeleton loading={isLoading}>
              <Meta
                style={{ whiteSpace: "normal" }}
                title={
                  <>
                    <p className="text-uppercase" style={{ color: "#345c64" }}>
                      {article.category === "socialmovement" ? (
                        <p>social movement</p>
                      ) : (
                        <p>{article.category}</p>
                      )}
                    </p>
                    <p className="text-leftIm">{article.title}</p>
                  </>
                }
                description={
                  article.short_des.length > 50
                    ? `${article.short_des.substring(0, 120)}...`
                    : article.short_des
                }
              />
            </Skeleton>

            <p
              className="my-3 text-left font-weight-bold"
              style={{
                lineHeight: "1",
                // color: '#d80650',
                color: "#345c64",
                display: "block",
              }}
            >
              {" "}
              โดย {author}
            </p>
          </Card>{" "}
        </Link>
      )}
    </>
  );
};

export default Article;
