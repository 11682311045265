import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth/authSlice';
import articleReducer from './features/article/articleSlice';
import authorReducer from './features/author/authorSlice';
import photoGrapherReducer from './features/photoGrapher/photoGrapherSlice';
import categoryReducer from './features/category/categorySlice';
import userReduser from './features/users/userSlice';
import employeeReduser from './features/employee/employeeSlice';
import departmentReducer from './features/department/departmentSlice';
import aboutUsReducer from './features/aboutUs/aboutUsSlice';

export const store = configureStore({
   reducer: {
      auth: authReducer,
      article: articleReducer,
      author: authorReducer,
      photoGrapher: photoGrapherReducer,
      category: categoryReducer,
      user: userReduser,
      employee: employeeReduser,
      department: departmentReducer,
      aboutUs: aboutUsReducer,
   },
});
