import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  List,
  Modal,
  Row,
  Select,
  Skeleton,
  Tag,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { TweenOneGroup } from "rc-tween-one";
import {
  addPhotoGrapherToArticle,
  editArticleData,
  getArticleById,
  removePhotographerArticle,
} from "../../features/article/articleSlice";
import DefaultLayout from "../components/DefaultLayout ";
import Resizer from "react-image-file-resizer";
import { uploadPic } from "../../utils/connect";
import { toast } from "react-toastify";
import { GoPlus } from "react-icons/go";
import styled from "styled-components";
import {
  getPhotoGrapher,
  reset,
} from "../../features/photoGrapher/photoGrapherSlice";
import { AiOutlinePlus } from "react-icons/ai";
const { Meta } = Card;
const { TextArea } = Input;

const EditArticleData = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const navigate = useNavigate();

  const [modalPG, setModalPG] = useState(false);

  const [media, setMedia] = useState(null);
  const [mediaPreview, setMediaPreview] = useState(null);
  const dispatch = useDispatch();

  const { article, isLoading, isAddPGSuccess,isSuccess } = useSelector(
    (state) => state.article
  );
  const { photoGraphers } = useSelector((state) => state.photoGrapher);

  let photoGraphers2 = photoGraphers.flat();

  let photoGrapherInArticle = null;
  let photoGrapherInArticleString = null;
  if (article.title) {
    photoGrapherInArticle = article.photoGraphers.map(
      (e) => e.photoGrapher._id
    );
    photoGrapherInArticleString = photoGrapherInArticle.toString();

    let articlePG = article.photoGraphers.map((e) => e.photoGraphers);

    // console.log(article.photoGraphers.name);
    // console.log(article.photoGraphers);
    // console.log(article);
  }
  let pg3 = null;
  if (article.title) {
    pg3 = photoGraphers2.filter((e) => {
      return photoGrapherInArticle.every((d) => {
        return e._id.toString() !== d.toString();
      });
    });
  }

  // let pg3 = null;
  // if (article.title) {
  //   pg3 = photoGraphers2.filter(
  //     (e) => e._id.toString() !== photoGrapherInArticleString
  //   );
  // }

  const [pgData, setPGdata] = useState({});

  useEffect(() => {
    dispatch(getPhotoGrapher());
    return () => {
      dispatch(reset());
    };
  }, []);

  // TAGS ///
  const [tags, setTags] = useState([]);
  const [tagsArticle, setTagsArticle] = useState([])

  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef();

  let tagsToDelete = [];
  const handleDeleteTags = (values) => {
    // if (tagsToDelete.includes(values)) {
    //   // Value already exists in array, skip pushing
    //   return;
    // }
    // tagsToDelete.push(values);
    // console.log(tagsToDelete);

    const newTags = tagsArticle.filter((tag) => tag !== values);
   setTagsArticle(newTags);
  };

  const inputTagsRef = useRef(null);
  useEffect(() => {

    if(article) {
      setTagsArticle(article.tags)
   }

    if (inputVisible) {
      inputTagsRef.current?.focus();
    }
  }, [article,isSuccess]);

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    console.log(newTags);
    setTags(newTags);
  };
  const showInput = () => {
    setInputVisible(true);
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };
  const forMap = (tag) => {
    const tagElem = (
      <Tag
      color="#2db7f5"
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
        }}
      >
        {tagElem}
      </span>
    );
  };
  const tagChild = tags.map(forMap);

  // TAGS END///

  useEffect(() => {
    dispatch(getArticleById(params.aid));

    form.setFieldsValue({
      title: article.title,
      short_des: article.short_des,
      category: article.category,
      enTitle: article.enTitle,
    });


    
  }, [
    isAddPGSuccess,
    params.aid,
    dispatch,
    article.title,
    article.category,
    article.enTitle,
    article.short_des,
    form,
  ]);

  const removePhotoGrapher = (id) => {
    let ids = { aid: "", pid: "" };
    ids.pid = id;
    ids.aid = article._id;

    dispatch(removePhotographerArticle(ids));
  };
  const addPG = (id) => {
    let data = { article, id };
    data.article = article;
    data.id = id;

    dispatch(addPhotoGrapherToArticle(data));

    //  window.location.reload()
    navigate(`/articles`);
  };

  const handelChange = (e) => {
    setMediaPreview(URL.createObjectURL(e.target.files[0]));

    let fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          500,
          500,
          "JPEG",
          100,
          0,
          (uri) => {
            setMedia(uri);
          },
          "base64",
          500,
          500
        );
      } catch (err) {}
    }
  };

  const onFinish = async (values) => {
    let pic;
    if (media !== null) {
      pic = await uploadPic(media);
    }
    values.cover = pic;
    values.aid = params.aid;

    let  newTags = []
    newTags.push(...tags,...tagsArticle)

    values.tags = newTags;

    console.log(values);

    dispatch(editArticleData(values));

    // toast.success("แก้ไขข้อมูลสำเร็จ");

    //  navigate("/articles");
  };

  return (
    <DefaultLayout>
      <Container>
        <Row gutter={24}>
          <Col
            span={12}
            // style={{ margin: 'auto' }}
          >
            <div className="d-flex justify-content-center my-3">
              {mediaPreview ? (
                <Image
                  className="m-auto"
                  preview={false}
                  height={100}
                  width={100}
                  src={mediaPreview}
                />
              ) : (
                <Image
                  className="m-auto"
                  style={{ border: ".5px solid black", padding: "60px" }}
                  preview={false}
                  height={300}
                  width={300}
                  src={article.cover}
                />
              )}
            </div>
            <input
              hidden
              id="upload"
              name="imgUpload"
              accept="image/*"
              type="file"
              onChange={handelChange}
            />
            <label
              style={{
                backgroundColor: "#cb8400",
                color: "white",
                padding: "1rem",
                fontSize: "16px",
                borderRadius: "1rem",
                margin: "1rem",
                cursor: "pointer",
              }}
              for="upload"
            >
              update profile picture{" "}
            </label>

            <Form
              form={form}
              initialValues={{
                title: article.title,
                enTitle: article.enTitle,
                short_des: article.short_des,
                category: article.category,
              }}
              layout="vertical"
              onFinish={onFinish}
            >
              <Form.Item name="title" label="ชื่อบทความ">
                <Input />
              </Form.Item>
              <Form.Item name="enTitle" label="ชื่อ-อังกฤษ">
                <Input />
              </Form.Item>

              <Form.Item name="short_des" label="คำอธิบาย">
                <TextArea />
              </Form.Item>

              <Form.Item name="category" label="หมวดหมู่">
                <Input />
              </Form.Item>
              <hr style={{color: 'black !important'}}/>

              <List
                split={false}
                grid={{
                  gutter: 16,
                  column: 4,
                }}
                dataSource={tagsArticle}
                renderItem={(item) => (
                  <List.Item>
                    {tagsToDelete === null ? (
                      <>
                        {tagsToDelete.includes(item) ? (
                          <Tag
                            color="#f50"
                            closable
                            onClose={(e) => {
                              e.preventDefault();
                              handleDeleteTags(item);
                            }}
                          >
                            {item}
                          </Tag>
                        ) : (
                          <Tag
                            closable
                            onClose={(e) => {
                              e.preventDefault();
                              handleDeleteTags(item);
                            }}
                          >
                            {item}
                          </Tag>
                        )}
                      </>
                    ) : (
                      <>
                        <Tag
                          closable
                          onClose={(e) => {
                            e.preventDefault();
                            handleDeleteTags(item);
                          }}
                        >
                          {item}
                        </Tag>
                      </>
                    )}
                  </List.Item>
                )}
              />

<hr/>
              <Form.Item label="แท็ก">
                <>
                  <div style={{ marginBottom: "16px" }}>
                    <TweenOneGroup
                      enter={{
                        scale: 0.8,
                        opacity: 0,
                        type: "from",
                        duration: 100,
                      }}
                      onEnd={(e) => {
                        if (e.type === "appear" || e.type === "enter") {
                          e.target.style = "display: inline-block";
                        }
                      }}
                      leave={{
                        opacity: 0,
                        width: 0,
                        scale: 0,
                        duration: 200,
                      }}
                      appear={false}
                    >
                      {tagChild}
                    </TweenOneGroup>
                  </div>
                  {inputVisible && (
                    <Input
                      ref={inputRef}
                      type="text"
                      size="small"
                      style={{
                        width: "100%",
                      }}
                      value={inputValue}
                      onChange={handleInputChange}
                      onBlur={handleInputConfirm}
                      onPressEnter={handleInputConfirm}
                    />
                  )}
                  {!inputVisible && (
                    <Tag
                    color="#87d068"
                      onClick={showInput}
                      style={{ width: "100%" }}
                      className="site-tag-plus"
                    >
                      <AiOutlinePlus /> New Tag
                    </Tag>
                  )}
                </>
              </Form.Item>

              {/* <Form.Item name='category' label='หมวดหมู่'>
                     <Select>
                        <Select.Option value='Type'>Type </Select.Option>
                        <Select.Option value='coffee'> Coffee </Select.Option>
                        <Select.Option value='dessert'>Dessert</Select.Option>
                        <Select.Option value='food'> Food </Select.Option>
                     </Select>
                  </Form.Item> */}

              <div className="d-flex justify-content-end">
                <Button htmlType="submit" type="primary">
                  {" "}
                  SAVE
                </Button>
              </div>
            </Form>
          </Col>
          <Col span={12}>
            {article ? (
              <>
                {article.author && (
                  <div>
                    <h3> นักขียน </h3>
                    <Skeleton avatar title={false} loading={isLoading} active>
                      <List.Item
                        actions={[
                          <p
                            style={{ cursor: "pointer" }}
                            key="list-loadmore-edit"
                            onClick={() =>
                              navigate("/admin/authorprofile", {
                                state: {
                                  record: article.author._id,
                                },
                              })
                            }
                          >
                            แก้ไข
                          </p>,
                        ]}
                      >
                        <List.Item.Meta
                          avatar={
                            <Avatar size={64} src={article.author.picUrl} />
                          }
                          title={<p>{article.author.name}</p>}
                          description={article.author.bio}
                        />
                      </List.Item>
                    </Skeleton>
                  </div>
                )}
                <br />
                {article.photoGraphers ? (
                  <div>
                    <h3> ช่างภาพ </h3>
                    <div>
                      {/* <List.Item
                        actions={[
                          <p>แก้ไข</p>,
                          <p
                            style={{ cursor: "pointer" }}
                            onClick={() => removePhotoGrapher(article._id)}
                          >
                            ลบ
                          </p>,
                        ]}
                      >
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              size={64}
                              src={article.photoGrapher.picUrl.url}
                            />
                          }
                          title={
                            <p href="https://ant.design">
                              {article.photoGrapher.name}
                            </p>
                          }
                          description={article.photoGrapher.bio}
                        />
                      </List.Item> */}
                      <List
                        className="demo-loadmore-list"
                        itemLayout="horizontal"
                        dataSource={article.photoGraphers}
                        renderItem={(item) => (
                          <List.Item
                            actions={[
                              <a
                                key="list-loadmore-edit"
                                onClick={() =>
                                  navigate("/admin/photoGrapherDetail", {
                                    state: {
                                      record: item.photoGrapher._id,
                                    },
                                  })
                                }
                              >
                                แก้ไข
                              </a>,
                              <a
                                onClick={() =>
                                  removePhotoGrapher(item.photoGrapher._id)
                                }
                              >
                                ลบ
                              </a>,
                            ]}
                          >
                            <Skeleton
                              avatar
                              title={false}
                              loading={isLoading}
                              active
                            >
                              <List.Item.Meta
                                avatar={
                                  <Avatar src={item.photoGrapher.picUrl.url} />
                                }
                                title={
                                  <a href="https://ant.design">
                                    {item.photoGrapher.name}
                                  </a>
                                }
                                description={item.photoGrapher.bio}
                              />
                              <div>content</div>
                            </Skeleton>
                          </List.Item>
                        )}
                      />
                      <>
                        <br />
                        <h5>ต้องการเพิ่มช่างภาพลงบทความหรือไม่ </h5>
                        <br />
                        <AddPG>
                          <GoPlus
                            onClick={() => setModalPG(true)}
                            style={{ fontSize: "48px", cursor: "pointer" }}
                          />
                        </AddPG>
                      </>
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}
          </Col>
        </Row>
      </Container>

      {modalPG && (
        <Modal
          title="ช่างภาพ"
          visible={modalPG}
          footer={false}
          width={800}
          onCancel={() => {
            setModalPG(false);
          }}
        >
          <Row gutter={24}>
            {pg3.map((e) => {
              return (
                <Col span={8}>
                  <Card
                    onClick={() => addPG(e._id)}
                    hoverable
                    cover={
                      <img
                        alt="img 1"
                        style={{ height: "150px", objectFit: "cover" }}
                        src={e.picUrl.url}
                      />
                    }
                  >
                    <p>{e.name} </p>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Modal>
      )}
    </DefaultLayout>
  );
};

const AddPG = styled.div`
  padding: 40px;
  border: 1px solid black;
`;

export default EditArticleData;
