import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import aboutUsService from './aboutUsService';

const initialState = {
   aboutUss: [],
   aboutUs: {},
   isError: false,
   isSuccess: false,
   isLoading: false,
   message: '',
};
export const createAboutUs = createAsyncThunk(
   'aboutUs/createAboutUs',
   async (data, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token;

         return await aboutUsService.createAboutUs(data, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         console.log(message);
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const updateAboutUs = createAsyncThunk(
   'aboutUs/updateAboutUs',
   async (data, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token;

         return await aboutUsService.updateAboutUs(data, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         console.log(message);
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const deleteAbout = createAsyncThunk(
   'aboutUs/deleteAbout',
   async (id, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token;

         return await aboutUsService.deleteAbout(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         console.log(message);
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getAboutUs = createAsyncThunk(
   'aboutUs/getAboutUs',
   async (_, thunkAPI) => {
      try {
         return await aboutUsService.getAboutUs();
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const aboutUsSlice = createSlice({
   name: 'aboutUs',
   initialState,
   reducers: {
      reset: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(getAboutUs.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getAboutUs.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.aboutUss = action.payload;
         })
         .addCase(getAboutUs.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(createAboutUs.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(createAboutUs.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.aboutUs = action.payload;
         })
         .addCase(createAboutUs.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(updateAboutUs.pending, (state, action) => {
            state.isLoading = true;
         })
         .addCase(updateAboutUs.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.aboutUs = action.payload;
         })
         .addCase(updateAboutUs.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(deleteAbout.pending, (state, action) => {
            state.isLoading = true;
         })
         .addCase(deleteAbout.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.aboutUss = action.payload;
         })
         .addCase(deleteAbout.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         });
   },
});

export const { reset } = aboutUsSlice.actions;
export default aboutUsSlice.reducer;
